import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/header.css";
import { navItems } from "../../assets/data/navItems";
import DropDown from "./dropDown";
import Img from "../../assets/all-image/logo/rnkLogo.jpg";

const Header = () => {
    const [dropdown, setDropdown] = useState(false);
    
    //{navigation resposive code} //
    const [isMobile, setIsMobile] = useState(false);

//------------------------------------------------------------<Header TOP>-----------------------------------------------------//
    return (
        <header>
            <div className="header__top">
                <Container>
                    <Row>
                        <Col lg="6" md="6" sm="6">
                            <div className="header__top__left">
                                <span className="header__top__help">
                                    <a href="https://www.facebook.com/profile.php?id=100091626220892/" target="_blank" rel="noreferrer">
                                        <i class="ri-facebook-box-fill"></i>
                                    </a>

                                    <a href="https://www.instagram.com/rnkrentacars/" target="_blank" rel="noreferrer">
                                        <i class="ri-instagram-line"></i>
                                    </a>

                                    <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
                                        <i class="ri-youtube-fill"></i>
                                    </a>
                                </span>
                            </div>
                        </Col>

                        <Col lg="6" md="6" sm="6">
                            <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                                <a href="tel:2243227799"><i class="ri-phone-fill"></i> +91-22 43227799</a>
                                <a href="mailto:info@rnk.com"><i class="fa fa-envelope"></i> info@rnk.com</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

{/*====================================================== main navigation =======================================================*/}
            <nav className="navbar">
                <Link to="/" className="navbar-logo">
                    <img src={Img} alt="" />
                </Link>
                <ul className={isMobile ? "nav-items-mobile" : "nav-items"}
                onClick={() => setIsMobile(false)}
                >
                    {navItems.map(item => {
                        if (item.title === "SERVICES") {
                            return (
                                <li
                                    key={item.id}
                                    className={item.cName}
                                    onMouseEnter={() => setDropdown(true)}
                                    onMouseLeave={() => setDropdown(false)}
                                >
                                    <Link to={item.path}>{item.title}</Link>
                                    {dropdown && <DropDown />}
                                </li>
                            );
                        }
                        return (
                            <li key={item.id} className={item.cName}>
                                <Link to={item.path}>{item.title}</Link>
                            </li>
                        );
                    })}
                </ul>
                <button 
                className="mobile-menu-icon"
                onClick={() => setIsMobile(!isMobile)}
                >
                    {isMobile ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
                </button>
            </nav>
    </header>
    );
};


export default Header;