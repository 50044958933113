import React from 'react';
import "../styles/privecy-policy.css";

const PrivecyPolicy = () => {
  return (
    <div className='privecy-container'>
        <div className='privecy'>
            <h2 className='privecy-title'>Privacy Policy</h2>
        </div>
        <div className='privcey-firstRule'>
            <p>
                This Privacy Policy (hereinafter, referred to as Policy) outlines the Company's practices, policies
                and procedures concerning the collection, use, and protection of information collected from the
                users of this App (here onwards may be called as Website or App either together or separately)
                Website/App, in whatever capacity they use this Website/App (hereinafter, referred to as You or
                Your). We are committed to protecting your online privacy while you use this Website either as a
                visitor or as a registered user. <br /> <br />
                You are hereby informed that company always collects information about you and your device
                data when you use our mobile applications, websites, and other online products and services
                (collectively called as the "services" here onwards) and through various interactions and
                communications with us. If you reside in India, the services that are provided by company and its
                partners or affiliated programs or entities, and also this Privacy Statement applies without
                prejudice to all of the information collected and used by company If you reside outside India, the
                services mentioned herewith are not provided by company but the Privacy Statement being
                mentioned here would still apply to information collected and used by Company.
            </p>
        </div>
        <div className='privcey-secondRule'>
            <h4>Scopeand implementation</h4>
            <p>
                This Policy and Our privacy practices in pursuance thereof are consistent with the relevant
                provisions contained under Information Technology (Reasonable Security Practices and
                Procedures and Sensitive Personal Data or Information) Rules, 2011 (hereinafter, referred to as
                Rules).

                By using this Website/App or any features thereof, you understand, acknowledge, and agree to
                the clauses and sub-clauses contained hereunder. In case you do not agree to any of the
                following clause(s) or sub-clause(s), you understand, acknowledge and agree that your sole
                remedy against the Company is the cessation of further use of this Website/App <br /><br />
                This Policy is incorporated by reference within Our Terms of Use Agreement and shall be read in
                conjunction with the said Terms of Use Agreement.<br /> <br />
                We reserve the right, in our sole discretion, to amend and update this Policy or any part thereof
                at any time. We shall notify any major amendment(s) in the Policy by a notice requesting perusal
                on the homepage, which shall run for fifteen (15) days from such amendment(s). All such
                amendments and updates shall come into effect immediately upon their posting on this Website
                page/App. You are, therefore, advised to visit this Website/App page periodically to remain
                informed about the latest changes effected to this Policy. Your continued usage of this
                Website/App constitutes your implied acceptance of the amended Privacy Policy.<br /> <br />
                This Privacy Statement (here onwards called as "Statement") applies to persons anywhere in the
                world that use our apps or services to request transportation (called as "Users" here onwards).
                This Statement may not apply to information that we collect from or about drivers, vehicles,
                partner, transportation companies, or any other persons who use company platform under their
                prevailing and valid transportation license (collectively "Agents"). If you interact with the services
                as both a User and Agent, the respective privacy statements apply to your different interactions
                accordingly.
            </p>
        </div>
        <div className='privecy-ThirdRule'>
            <h4>PERSONALLY IDENTIFIABLE INFORMATION - COLLECTION, NON-COLLECTION AND USAGE</h4>
            <h5>Sensitive Personal Data or Information</h5>
            <p>
                We do not collect any Sensitive Personal Data or Information, as defined under Rule 3 of the
                Rules, from any user of this Website/App. In the event you provide a feedback to our
                website/App or leave a testimonial for Us, We shall treat all the personally identifiable information
                contained in such feedback/testimonial in accordance with the provisions contained herein.<br /> <br />
                All Your personally identifiable information collected is kept confidential and never shared with, or
                sold to, or otherwise commercially traded with, any third party without your prior express written
                consent. <br/><br/>
                We as a Technology platform collect information that you provide to us, eg., when you create a
                new account or modify your existing account, request transport services, contact customer care
                of respective Agents, or otherwise communicate with us in any manner. This type of interaction
                may contain information that may include: your name, email, phone number, postal address,
                profile picture, payment method, items requested, feedback/notes, and other information you
                choose to provide.<br /><br />
            </p>
        </div>
        <div className='privecy-FourthRule'>
            <h4>Data that we collect through use of our services</h4>
            <h6>Location based information:</h6>
            <p>
                Whenever you use the services for our transportation, we collect precise location data about the
                trip through the company app. If you permit company app to access location services through
                the permission system used by your mobile operating system (called as "platform" here
                onwards), we also collect the precise location of your device when the app is running in the
                foreground or background. We may also derive your approximate location from your IP address.
            </p>
            <h6>Device transaction Information:</h6>
            <p>
                We may collect transactional details related to your use of our services. This may be including
                the type of service requested for your trip, date and time the service was provided, amount
                charged, distance traveled, and other related transactional details. This is required for various
                activities highlighted here in this Privacy statement. We may also regularly collect information
                about how you interact with our services, type of preferences expressed, and device/App
                settings chosen. In some cases we do this through the use of cookies, tags, archives and similar
                technologies that create and maintain unique identifiers. Further, we may also collect information
                about your mobile device such as the device/hardware model, operating system and version,
                software and file names and versions, language preference, unique device identifier, advertising
                identifiers, serial number, device motion information, and mobile network information etc.
            </p>
            <h6>Telephone Call and SMS:</h6>
            <p>
                Our services facilitate communications between App users and Agents/drivers. In connection
                with this service, we may receive call data, date and time of call or SMS, phone numbers, and
                content of the SMS wherever possible.
            </p>
            <h6>Data Logs (offline information):</h6>
            <p>
                Whenever you interact with our services, we may collect server logs, which may include
                information such as your device IP address, access dates and times, app features or pages
                viewed, app crashes and other system activity, type of browser, and the third-party site or service
                you were using before interacting with our services.
            </p>
            <h6>Your contacts Information:</h6>
            <p>
                If you wish you can permit company app to access the address book/Contacts on your device.
                This can be done through the permission system used by your mobile platform settings, and we
                may access and store names and contact information from your address book to facilitate
                recommendations or social interactions through our services as described in this Statement and
                this information will not be used/shared outside the scope for any commercial purposes.
            </p>
            <h6>Various permissions on the device: </h6>
            <p>
                Usually mobile platforms have well defined structure or setup that prevents access to data
                without user consent. And these platforms have different permission systems configured for get
                your consent. For example, the apple iOS platform will alert you the first time the company app
                wants permission to access certain types of data and will let you provide your consent (or not
                consent) to that request. Same way, Android devices will notify you of the permissions that the
                company app seeks before you first use the app, and your use of the app constitutes your
                consent. However, the permissions we request will change over time, so we've created these
                useful sections to serve as up-to-date resources for our users.
            </p>
            <h6>Where else we gather the information?</h6>
            <p>
                For your information, we may also receive information from other sources and combine that with
                information we collect through our services. For instance:
            </p>
        </div>

        <div className='privecy-fifthRule'>
            <h4>How do we use the Information?</h4>
                <p className='span-value1'>
                    To provide, maintain, and improve our services, including, for example, to
                    facilitate payments, send receipts, provide products and services that you
                    request (and send related information), develop new features, provide
                    customer support to Users and Agents, develop safety features, authenticate
                    users, and send product updates and administrative messages.<br />
                </p>

                <p className='span-value2'>
                    To perform internal operations, including, for example, to prevent fraud and
                    abuse of our services; to troubleshoot software bugs and operational
                    problems; to conduct data analysis, testing, and research; and to monitor and
                    analyze usage and activity trends.<br />
                </p>

                <p className='span-value3'>
                    To facilitate communications between you and an Agent/Driver, times of
                    arrival (ETAs).
                </p>

                <p className='span-value4'>
                    To send you communications that we think will be of interest to you, including
                    information about our products, services, promotions, news, and events of
                    company and other companies, where permissible and according to local
                    applicable laws; and to process contest, sweepstake, or other promotion
                    entries and fulfill any related awards.
                </p>

                <p className='span-value5'>
                    To personalize and improve the services, including to provide or recommend
                    newer features, content, social connections, referrals, and advertisements.
                </p>

                <p>
                    We may transfer the information described in this section to, and process and store it
                    in, other countries, which may have less protective data protection laws than the
                    region in which you reside. Regardless, we will take appropriate measures to protect
                    your personal information.
            </p>
        </div>

        <div className='privecy-policy'>
            <h4>Sharing information</h4>

            
                <p className='privecy-value1'>
                    With Agents/Drivers to enable them to provide the services you request. For
                    example, we share your name, photo (if you provide one), average user rating
                    given to Drivers, and pickup and/or drop-off locations.
                </p>

                
                <p className='privecy-value2'>
                    With other/third parties to provide you a service you requested through a
                    partnership or promotional offering made by a third party or us
                </p>

                
                <p className='privecy-value3'>
                    With the general public if you submit content in a public forum, such as blog
                    comments, social media posts, or other features of our services that are
                    viewable by anybody
                </p>

                
                <p className='privecy-value4'>
                    With company subsidiaries and affiliated entities that provide services or
                    conduct data processing on our behalf, or for data analytics, centralization
                    and / or logistics purposes
                </p>

                
                <p className='privecy-value5'>
                    With vendors, consultants, marketing partners, and other service providers
                    who need access to such information to carry out work on our behalf;
                </p>

                
                <p className='privecy-value6'>
                    With vendors, consultants, marketing partners, and other service providers
                    who need access to such information to carry out work on our behalf;
                </p>

                
                <p className='privecy-value7'>
                    With law enforcement officials, government authorities, or other third parties if
                    we believe that your actions are inconsistent with our User agreements,
                    Terms of Service, or policies, or to protect the rights, property, or safety of
                    company its users or others
                </p>

                
                <p className='privecy-value8'>
                    In response to a request for information (if any) by a competent legal authority
                    if we believe disclosure is in accordance with, or is otherwise required by, any
                    applicable law, regulation, or legal requirements
                </p>

                
                <p className='privecy-value9'>
                    In connection with any negotiations of, any merger, sale of company assets,
                    consolidation or restructuring, financing, or acquisition of all or a portion of our
                    business by or into another company
                </p>

               
                <p className='privecy-value10'>
                    In an aggregated and/or anonymous form which cannot reasonably be used
                    to identify you
                </p>
        </div>

        <div className='privecy-social'>
            <h4>Social media sharing</h4>
            <p>
                The services may integrate with social sharing features and other related tools which
                let you share actions you take on our services with other apps, sites, or media, and
                vice versa. Your use of such features enables the sharing of information with your
                friends or the public, depending on the settings you establish with the social sharing
                service. Please refer to the privacy policies of those social sharing services for more
                information about how they handle the data you provide to or share through them.``
            </p>

            <h4>Analytics and Advertising services</h4>
            <p>
                From time to time we may allow others to provide audience measurement and
                analytics services for us, to serve advertisements on our behalf across the Internet,
                and to track and report on the performance of those advertisements. These entities
                may use cookies, web beacons, SDKs, and other technologies to identify your
                device when you visit our site and use our services, as well as when you visit other
                online sites and services. For more information about these technologies and service
                providers, please refer to our cookie policy.
            </p>

            <h4>Your Account Information</h4>
            <p>
                You can correct your account related information at any time by logging into in-app
                account. In case if you wish to cancel your account, please email us at
                info@rnk.com Also, please note that in some cases we may retain certain
                information about you as required by local law, or for legitimate business purposes to
                the extent permitted by law. For example, if you have a standing credit or debt on
                your account, or if we believe you have committed fraud or violated our Terms, we
                may seek to resolve the issue legally before deleting your information.
            </p>

            <h4>What are your access rights?</h4>
            <p>
                Company will comply with requests regarding access, correction, and/or deletion of
                the personal data it stores in accordance with applicable law. We request your
                permission for our app's collection of precise location from your device as per the
                permission system used by your mobile operating system. If you have initially
                permitted the collection of this information, you can always disable it by changing the
                location settings on your mobile device. However, this may limit your ability to use
                certain features of our services. Additionally, disabling the app's collection of precise

                location from your device will not limit our ability to collect your trip location
                information from your trip History on the App itself.
            </p>

            <h4>Your contact Information</h4>
            <p>
                We may also request your permission for our app's collection and syncing of contact
                information from your device along with the permission system used by your mobile
                operating system. If you initially permit the collection of this information, iOS users
                can later disable it by changing the contacts settings on your mobile device. The
                Android platform does not provide such a setting.
            </p>

            <h4>Promotional communications from us</h4>
            <p>
                You may wish to opt out of receiving promotional messages from us by following the
                instructions from your telephone service provider or from us as mentioned in those
                messages. If you opt out, we may still send you non-promotional communications,
                such as transactional ones like those about your account, about services you have
                requested, or our ongoing business relations.
            </p>

            <h4>Changes to the statement (Major and minor)</h4>
            <p>
                We change this statement from time to time. And in case if we make significant
                changes in the way we treat your personal information, or to the statement, we will
                provide you notice through the services or by some other means, such as email.
                Your continued use of the services after such notice means your consent to the
                changes. We encourage you to periodically review the statement for the latest
                information on our privacy practices.
            </p>

            <h4>Contact us</h4>
            <p>
                If you have any questions about this Privacy Statement, please contact us at 022-
                43227799 or write us at info@rnk.com our official address that you can find on our
                website.
            </p>
            <h4>COOKIE STATEMENT (GLOBALLY VALID)</h4>
                <p>We and our affiliates, third parties, and other partners may use cookies to collect the
                aforementioned non-personally identifiable information from the users of this
                App/Website regularly. Cookies are small text files that are placed on the hard drive
                of your computer system by a Web page server. The cookies are meant to determine
                such information as: how you use Our Website/App, how you use our services in
                general, types of data accessed, time and volume of use, duration of sessions and
                other similar usage. The cookies are also used to avoid repeat login while the
                session is still open. Our cookies do not, in any way, compromise on your online
                privacy or damage your device/computer system in any manner whatsoever. There
                are different types of cookies. Cookies served by the entity that operates the domain
                you are visiting are called "first party cookies' So cookies served by RNK while you
                are on website are first party cookies. Cookies served by companies that are not
                operating the domain you're visiting are called 'third party cookies' So, we may allow
                Google to set a cookie on your browser while you visit our RNK website, and that
                would be a third party cookie. Cookies may also endure for different periods of time.
                "Session Cookies" only last only as long as your browser is open. These are deleted
                automatically once you close your browser. Other cookies are "persistent cookies"
                meaning that they survive after your browser is closed. For example, they may
                recognize your device when you re-open your browser and browse the internet
                again. However, you shall be solely responsible for regularly clearing cookies,
                cached data and temporary internet files from your devices/computer system in order
                to manage the downloaded data, if any.
                </p>

            <h4>For Non-EU Users:</h4>
            <p>
                The non-European Union (EU) users can refuse to accept the cookies from this
                Website/App by managing the Browser/Settings options and/or preferences.
                However, for efficient use and enjoy all the features of this Website/App, We strongly
                advise against refusing the cookies from Our Website (website users only)
            </p>

            <h4>For EU Users:</h4>
                <p>
                Pursuant to Directive 2009/136/EC of the European Parliament and of the Council of
                25 November 2009 amending Directive 2002/22/EC on universal service and users
                rights relating to electronic communications networks and services, Directive
                2002/58/EC concerning the processing of personal data and the protection of privacy
                in the electronic communications sector and Regulation (EC) No 2006/2004 on
                cooperation between national authorities responsible for the enforcement of
                consumer protection laws; if You belong to any of the EU countries, You are required
                to accept the cookies from Our Website by clicking Yes on the Dialogue Box that
                appears when We want to deposit a cookie on Your computer system.
                </p>

            <h4>Use of Non-Personally Identifiable Information:</h4>
                <p>
                We use non-personally identifiable information to improve the layout and content of
                Our Website/App, provide you with customised advertisements, analyse the
                Website/App usage, and enhance your overall experience of using this Website/App
                All such non-personally identifiable information so collected is kept confidential and
                never shared with, or sold to, or otherwise commercially traded with, any third party
                without your prior express written consent. However, we may share all or part of
                such non-personally identifiable information with trusted third party providers,
                advertisers and/or suppliers who work on our behalf in order to provide you with
                efficient and timely services. However, all such third party providers, advertisers
                and/or suppliers are under appropriate burden of confidentiality not to share,
                disclose, or independently use any of the non-personally identifiable information so
                collected.
                </p>

            <h4>THIRD PARTY LINKS</h4>
                <p>
                This Website/App may also contain links to third-party websites belonging to our
                promoters, advertisers, affiliates, partners, agents, etc. The presence of these links

                on this Website does not, in any way, signify our endorsement of such linked
                websites. In fact, we do not review, control or monitor the privacy practices and/or
                content of such third party websites.
                When you click on such third party website links, you may be directed to their
                Website, which may use cookies and Web Beacons (Clear GIF files) to keep track of
                your activities on their website. Your use of such third party linked websites is
                subject to the privacy policies and practices of these third party websites. This Policy
                does not cover your transactions and dealings with any third party website whose
                link appears on this Website.
                </p>

            <h4>Other Technologies we may use</h4>
                <p>
                    'Pixel tags' (also called beacons or pixels in general) are small blocks of code
                    installed on (or called by) a webpage, app, or advertisement which can retrieve
                    certain information about your device and browser, including for example: device
                    type, operating system, browser type and version, website visited, time of visit,
                    referring website, IP address, and other similar information, including the small text
                    file (the cookie) that uniquely identifies the device. These Pixels provide us the
                    means by which third parties can set and read browser cookies from a domain that
                    they do not themselves operate and collect information about visitors to that domain,
                    typically with the exclusive permission of the domain owner. 'Local storage' refers
                    generally to other places on a browser or device where information can be stored by
                    websites, ads, or third parties (like, HTML5 local storage and browser cache).
                    'Software Development Kits' (also called SDKs globally) function like pixels and
                    cookies, but operate in the mobile app context where pixels and cookies cannot
                    always function. The primary app developer can install pieces of code (the SDK)
                    from partners in the app, and thereby allow the partner to collect certain information
                    about user interaction with the app and information about the user device and
                    network information.
                </p>

            <h4 >PROTECTION OF PERSONALLY AND NON-PERSONALLY IDENTIFIABLEINFORMATION</h4>
                <p>
                    We have deployed certain physical and procedural safeguards to ensure that only
                    authorised personnel have access to the data so collected from the users of this
                    Website/App. However, due to peculiar nature of the Internet and ever-evolving
                    technology, we cannot ensure foolproof security from unauthorised access to
                    sensitive data stored on our servers.
                </p>

            <h4>DISCLOSURE OF INFORMATION</h4>
                <p>
                    This Policy shall not be applicable under any of the following circumstances; and
                    consequently, the following disclosures of personally and non-personally identifiable
                    information shall be out of the purview of the provisions contained herein:
                </p>
                <p className='privecy-value1'>
                    Disclosure to any of the government agencies in compliance with legal
                    obligations of the Company. However, such government agencies shall be

                    under legislative obligation not to publish or share any information so obtained
                    with any other person; or
                </p>

                
                <p className='privecy-value2'>
                    Any unlawful or unauthorised access to, and transmissions of, personally
                    identifiable information from this Website; or
                </p>

                
                <p className='privecy-value3'>
                    Disclosure of any information by the user himself/herself on Message Boards,
                    Public Forums, Blogs, Social Networking websites, or any other publicly
                    accessible online or offline media; or
                </p>

                
                <p className='privecy-value4'>
                    Disclosure of such information as an asset on account of sale, part sale,
                    merger, acquisition of this Website to a third party or similar arrangement,
                    which requires such disclosure to a third party; or
                </p>

                
                <p className='privecy-value5'>
                    Disclosure in order to enforce Our Terms of Use Agreement; or
                </p>

                
                <p className='privecy-value6'>
                    Disclosure in order to protect and defend the rights and interests of the
                    Company, users of this Website, and any contracted third party; or
                </p>

               
                <p className='privecy-value7'>
                    Disclosure to internal or external auditors appointed by the Company in order
                    to investigate the complaints of fraud, irregularities, etc.
                </p>

                <p>
                    Besides the aforementioned exceptions, any disclosure, of personally or non-
                    personally identifiable information so collected, in pursuance of the provisions
                    contained herein shall not be called into question by anyone at any time and for no
                    reason whatsoever.
            </p>

            <h4>Contact Us</h4>
            <p>
                If you still have privacy concerns or want to send suggestions, please contact us
                through any of the following modes. It shall be our endeavour to address all your
                concerns about your privacy on this App/Website.
                info@rnk.com
            </p> 

            <p>
                NOTE:- Rates are subject to availability of the vehicle & duration of the rental. Rates quotations are sent privately to customer’s email address.
            </p>      
        </div>
    </div>
  );
}

export default PrivecyPolicy;