/* eslint-disable react/jsx-no-undef */
import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import '../../styles/footer.css';

const quickLines = [
    {
        Path: '/about',
        display: "About Us"
    },

    {
        Path: 'TermsCondition',
        display: "Terms & Condition"
    },

    {
        Path: 'PrivecyPolicy',
        display: "Privacy Policy"
    },

    {
        Path: '/Fleet',
        display: "Car Fleet"
    },

    {
        Path: '/Contact',
        display: "Contact Us"
    },

    {
        Path: '/OurNetwork',
        display: "Our Network"
    },
]

const serviceDropdown = [
    {
        path: "/localduty",
        display: "Local Duty",
    },

    {
        path: "/hoteloperation",
        display: "Hotel Operation / Travel Desk",
    },

    {
        path: "/vipservices",
        display: "VIP Service",
    },
    
    {
        path: "/airport",
        display: "Airport / Railway Station Transfer",
    },

    {
        path: "/longtermcarrent",
        display: "Long Term Car Rental",
    },

    {
        path: "/luxurytravel",
        display: "Luxury Travel",
    },
];

const Footer = () => {

    const date = new Date()
    const year = date.getFullYear();

    return <footer className="footer">
        <Container>
            <Row>
                <Col>
                    <div className="footer__class">
                        <h5 className="footer__link-title">Quick Links</h5>
                        <ListGroup>
                            {
                                quickLines.map((item, index) => (
                                    <ListGroupItem key={index} className='quick__link'>
                                        <Link to={item.Path}>{item.display}</Link>
                                    </ListGroupItem>
                                ))
                            }
                        </ListGroup>
                    </div>
                </Col>
                <Col>
                    <div className="footer__class">
                        <h5 className="footer__link-title">Services</h5>
                        <ListGroup>
                            {
                                serviceDropdown.map((item, index) => (
                                    <ListGroupItem key={index} className='quick__link'>
                                        <Link to={item.path}>{item.display}</Link>
                                    </ListGroupItem>
                                ))
                            }
                        </ListGroup>
                    </div>
                </Col>

                <Col>
                    <div className="footer__class">
                        <div className="office__details">
                            <h5>Head Office Address</h5>
                            <p>Suite 19,<br />
                               Kaliandas Udyog Bhavan, <br />
                               Century Bazar, Prabhadevi,<br />
                               Mumbai, 400025 India <br />
                            </p>
                        </div>
                    </div>
                </Col>
                
                <Col>
                    <h3 className="link__title-Follow">Follow Us</h3>
                    <div className="follow__us">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=100091626220892/" target="_blank" rel="noreferrer" className="fab">
                                    <i class="ri-facebook-box-fill"></i>
                                </a>
                            </li>

                            <li>
                                <a href="https://www.instagram.com/rnkrentacars/" target="_blank" rel="noreferrer" className="fab">
                                    <i class="ri-instagram-line"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/" target="_blank" rel="noreferrer" className="fab">
                                    <i class="ri-youtube-fill"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="phone__links">
                        <li><a href="tel:2243227799"><i class="fa fa-phone" aria-hidden="true" className="fab">
                        </i>&nbsp;For Booking (24x7) : +91-22-43227799</a></li>
                    </div>

                    <div className="newsletter">
                        <form >
                        <input type="email" placeholder="Subscribe Newsletter"
                         />
                        </form>
                        <span>
                            <i class="ri-send-plane-line"></i>
                        </span>
                    </div>
                </Col>

                <Col lg="12">
                    <div className="footer__bottom">
                        <p className="section__description d-flex
                        align-items-center justify-content-center gap-1 pt-4">
                            <i class="ri-copyright-line">Copyright {year}, All Right Reserved To RNK <br/>
                            <p className="trademarks">
                            All trademarks, logos and brand names are the
                             property of their respective owners. All company, product and service 
                             names used in this website are for identification purposes only. Use of these names, trademarks and brands does
                             not imply endorsement.
                            </p>
                             </i>
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    </footer>
};

export default Footer;