import React from 'react';
import "../styles/luxury-travel.css";
import { useNavigate } from 'react-router-dom';


function LuxuryTravel() {
    const navigate = useNavigate();

    return (
        <div className='outstation__operation-container'>
            <div className='outstation__operation-page'>
            </div>
            <div className='luxury__title'>
                Outstation Travel
                <p>
                    Adding luxury to your outstation journeys!                
                </p>
                </div>

            <div className='summary'>
                <p>
                    Our services are designed to provide you with the best possible luxury car rental
                    experience for all your outstation travel needs. At RNK, we pride ourselves on
                    providing top-of-the-line luxury vehicles that are maintained to the highest
                    standards, with chauffeurs who are experienced, knowledgeable, pleasant and
                    professional.
                    Our outstation luxury car rental services are perfect for individuals and organizations
                    that need to travel out of town for business or leisure. We offer a wide range of luxury
                    cars, including sedans, SUVs, luxury coaches that are sure to make your journey
                    comfortable and luxurious. From planning out the shortest routes to mapping out
                    rest stops, we take care of your complete journey. At RNK, we believe that the
                    journey is more important than the destination.
                </p>

                <div className='special__service'>
                <h1>Our Special Services-</h1>
                <p>
                    In addition to luxury cars, we also offer group travel services in luxury AC buses
                    and vans. Our buses are perfect for large groups, and we offer a variety of sizes
                    to suit your needs.<br/><br/>

                    Our cars are equipped with all the latest technology, including GPS, Wi-Fi on
                    request and Bluetooth, so you can stay connected while on road. Our vehicles
                    are equipped with comfortable seating, air conditioning, and entertainment
                    systems, ensuring that your outstation travel experience is comfortable and
                    enjoyable.
                </p>
                <div className='luxury__rent'>
                <h1>Luxury rental car services of your choice</h1>
                <p>
                    Remember, the journey is often more important than the destination, so travel in
                    comfortable luxury with RNK.<br/><br/>
                    Contact us today to learn more about our outstation luxury car rental services.
                </p>

                <div className='why__rnk'>
                    <h1>Why RNK?</h1>
                    <p>
                    If luxury is what you are looking for - you are at the perfect place. RNK offers more
                    than just premium cars. Here is why you should choose our services -
                    </p>
                </div>
                <div className='Chauffeur__driven'>
                    <h1>Chauffeur-driven luxury</h1>
                    <p>
                    When you rent a chauffeur-driven luxury car, you don't have to worry about driving
                    and navigating through unfamiliar roads or traffic. A professional chauffeur can
                    handle all aspects of the ride, allowing you to relax, work, or enjoy the scenery.
                    </p>
                </div>
                <div className='gps__enabled'>
                    <h1>GPS-enabled</h1>
                    <p>
                    A GPS-enabled luxury car is secure and can help you easily navigate in unknown
                    locations. This can be particularly helpful if you're travelling to a new city or country
                    and need to find your way around quickly and efficiently. The GPS-enabled system
                    also allows tracking of the vehicle to maintain the security of the passengers.
                    </p>

                    <div className='Security'>
                        <h1>Security</h1>
                        <p>
                        A secure luxury car prioritizes the safety of its customers by providing well-
                        maintained and reliable vehicles. This can help in making sure that you arrive at your
                        destination safely and on time.
                        </p>
                    </div>

                    <div className='Comfort'>
                        <h1>Comfort fit for VIPs</h1>
                        <p>
                        The vehicles are equipped with features such as plush seating, climate control, and
                        advanced sound systems, allowing you to ride conveniently.
                        </p>
                    </div>

                    <div className='Extensive'>
                        <h1>Extensive fleet of luxury cars</h1>
                        <p>
                         With more options, you can select a luxury car that is most suitable for your specific
                        occasion or event. We have various luxury vehicles such as BMW, Audi, Mercedes
                        and so on. <button onClick={() => { navigate('/fleet') }} className="link__fleet-feild">Click Here</button> to check our entire fleet
                        </p>
                    </div>

                    <div className='support'>
                        <h1>24/7 support on-call</h1>
                        <p>
                        You will get immediate assistance and help to quickly resolve issues if any, ensuring
                        your travel plans are maintained.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="accordion">
                <div className='accordion__title'>FAQs</div>
                <div>
                    <input type="checkbox" name='example__accordion' id='section1' className='accordion__input' />
                    <label htmlFor="section1" className='accordion__label'>Can I rent a luxury car for a one-way trip with outstation rental services?</label>
                    <div className='accordion__content'>
                        <p>
                            Yes, RNK outstation luxury car rental services offer one-way rentals.
                        </p>
                    </div>
                </div>
                <div className='main__accordion'>
                <div>
                    <input type="checkbox" name='example__accordion' id='section2' className='accordion__input' />
                    <label htmlFor="section2" className='accordion__label'>What car options are available for outstation rental services?</label>
                    <div className='accordion__content'>
                        
                        <p>
                        we offer a diverse fleet of luxury vehicles to choose from. You can specify your
                        preference for a sedan, SUV, or any other type of luxury car and we will strive to
                        accommodate your request based on availability. <button onClick={() => { navigate('/fleet') }} className="link__fleet-feild">Click Here</button> to check our entire fleet.                        </p>
                    </div>
                </div>

                <div>
                    <input type="checkbox" name='example__accordion' id='section3' className='accordion__input' />
                    <label htmlFor="section3" className='accordion__label'>Can you take rental cars out of state in India?</label>
                    <div className='accordion__content'>
                        <p>
                            All our cars at RNK have all India permit and can be taken anywhere in India.
                        </p>
                    </div>
                </div>

                <div>
                    <input type="checkbox" name='example__accordion' id='section4' className='accordion__input' />
                    <label htmlFor="section4" className='accordion__label'>Are your luxury vehicles equipped with advanced security features?</label>
                    <div className='accordion__content'>
                        <p>
                        Absolutely. All our luxury vehicles are equipped with state-of-the-art security
                        features, including advanced alarm systems, GPS tracking, and secure locking
                        mechanisms, to safeguard against theft or unauthorized access.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default LuxuryTravel;
