import React, { useState } from 'react';
import "../styles/local-car-booking-form.css";

//import img01 from "../../assets/all-image/slider/locals.jpg";
// import img02 from "../../assets/all-image/slider/hotelwithcar.jpg";
// import img03 from "../../assets/all-image/slider/bmw1.jpg";
// import img04 from "../../assets/all-image/slider/hero2.jpeg";
// import img05 from "../../assets/all-image/slider/hero3.jpeg";
// import img06 from "../../assets/all-image/slider/hero8.jpeg";
import imageSlider from "../assets/all-image/slider/aboutImg.png";

function LocalCarBookingForm() {

    const [formData, setFormData] = useState({});

    const [data, setData] = useState({
        name: "",
        phone: "",
        email: "",
        city: "",
        vehicle: "",
        date: "",
        duty: "",
    });

    const { name, phone, email, city, vehicle, date, duty } = data;

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value });
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        
        try {
            const response = await fetch("https://v1.nocodeapi.com/anshujha1999/google_sheets/zSYoYPtpSNndkrCD?tabId=LocalCarBookingData", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([[name, phone, email, city, vehicle, date, duty, new Date().toLocaleString()]])
            });
            await response.json()
            setData({ ...data, name: "", phone: "", email: "", city: "", vehicle: "", date: "", duty: "" })

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="localcar__form">
            <form className="form__details" onSubmit={handleSubmit}>
                <div className="form__first">
                    <div className="details personal">
                        <h2 className="title">Book Your Car Now!</h2>

                        <div className="feilds">
                            <div className="input-feild">
                                <input type="text" name='name' id='name' placeholder='Name' required
                                    value={name} onChange={handleChange} />
                            </div>
                            <div className="input-feild">
                                <input type="tel" name="phone" id="phone" placeholder="Phone" required
                                    value={phone} onChange={handleChange} />
                            </div>

                            <div className="input-feild">
                                <input type="email" name="email" id="email" placeholder="Email" required
                                    value={email} onChange={handleChange} />
                            </div>
                            <div className="input-feild">
                                <input type="text" name="city" id="city" placeholder="City" required
                                    value={city} onChange={handleChange} />
                            </div>

                            <div className="input-feild">
                                <input type="text" name='vehicle' id='vehicle' placeholder='Vehicle Type' required
                                value={vehicle} onChange={handleChange} />
                            </div>

                            <div className="input-feild">
                                <input type="date" name="date" id="date" required
                                    value={date} onChange={handleChange} />
                            </div>

                            <div className="input-feild">
                                <input type="text" name='duty' id='duty' placeholder='Duty Type' required
                                value={duty} onChange={handleChange} />
                            </div>

                            <div className="input-feild">
                                <button type="submit" className="btn">Book Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='image__slider'>
                        <img src={imageSlider} alt="" className='image__page' />
                    </div>
                </div>
            </form>
            <div className='warning__message'>
                <p><span>*</span> Please note we will reply within 24 working hours. For last minute query requested to call us on +91-22-43227799 </p>
            </div>
        </div>
    )
};

export default LocalCarBookingForm;


