import React from 'react';
import "../styles/term-condition.css";

const TermsCondition = () => {
  return (
    <div className='terms-container'>
        <div className='terms-title'>
        <h2>Terms & Condition</h2>
        </div>
        <div className='terms-para1'>
            <h2>CHAUFFEUR DRIVEN CARS</h2>
            <h5>Eligibility</h5>
            <p>
            Should be of minimum 18 years and above. - Children below the age of 18 years
            can't travel alone using Company’s services/Car and must be accompanied by an
            adult at all times.
            </p>
        </div>
        <div className='booking-process'>
            <h5>Booking Process:</h5>
            <p>
            Booking will be made online at the Company’s website being www.rnk.com OR
            offline at Company’s office, details of which are provided by us in the said website.
            The booking mode shall be based on Journey as well as the type of car preferred. <br />
            </p>
            <p>
            All Tolls, Taxes and Parking Fees are borne by the customer alone and shall be
            included in their final bills on actual. All distances and time are calculated from
            Garage to Garage. Any damages to the vehicle either temporary or permanent, done
            by the customer will have to be fully reimbursed by the customer to the extent that
            the vehicle is fully backed up into operation.
            </p>
            <p>
            Airport transfers are not part of the outstation/local trips. Please verify with us if you
            are planning Airport pickup/Drop off service.
            </p>
        </div>
        <div className='customer-response'>
            <h5>Customer Responsibilities</h5>
            <p>
            Our chauffeurs will drive cars as per the legal RTO regulations put forth in each city
            with respect to speed, routes and style of driving
            </p>
            <p>
            Influencing the chauffeur to drive rashly, at high speed or abusing Company’s
            chauffeurs in any way is strictly prohibited. Customer is strictly prohibited from
            misbehaving and/or using force against Company’s chauffeurs under 
            <span className='indemnity-span'>circumstances.  Any such abusive behaviour of Customer shall not be tolerated by
            the Company and a suitable action may be taken against such Customer as per legal
            advice to the Company. </span>
            </p>
            <p>
            In the event of any complaints against Chauffeur is to be made by the Customer then
            the same must be directed to the Company, at any point in time before, during or

            after the journey. The Company reserves its rights to enquire into the same and to
            <span className='indemnity-span'> deal with it appropriately.</span>
            </p>
            <p>
            Adding more passengers than the legal limit is strictly not allowed and Company’s
            chauffeurs shall be within his duty and rights if he refuses to allow more passengers
            and the vehicle is returned by him to the garage in such cases. Needless to mention
            <span className='indemnity-span'>that customer will be charged for the same and no refund will be given.</span>
            </p>
            <p>
            Our chauffeurs will have full authority to deny boarding to customers for carrying any
            illegal substances like alcohol, food and drink or any other potential hazards which
            may compromise safety and features of the vehicle or journey. He shall be right in
            <span className='indemnity-span'>simply returning back to our office OR the Company shall be within its legal
            parameters if Company calls back its driver due to this reason. The Customer shall
            have no claim over money deposited by him/her/it and Company shall be at liberty to
            forfeit</span> <span className='indemnity-span'>the same.</span>
            </p>

            <p>
            Carrying illegal Firearms, ammunition, drugs or any other illegal activities is
            prohibited in the Car. In the event customer is found with such illegal articles and or
            carrying out illegal activities then our chauffeur shall be constrained to end the
            journey <span className='indemnity-span'> forthwith immediately upon realization and return the vehicle back to office.
            In addition to the Company’s right to claim for entire journey and/or forfeit deposit
            amount, the Company may also be constrained to intimate about such illegal
            activity/ies to authorities</span> <span className='indemnity-span'> for appropriate action, if needed.</span>
            </p>

            <p>
            Customers will be responsible for any damage that may occur to the car or chauffeur
            if they influence the chauffeur to drive/do things that are not in the purview of the
            agreed plan/trip/contract.
            </p>

            <p>
            In Chauffeur driven cars, maximum driving duration is for 12Hrs and 300 Kms only
            [whichever occurs first]. We reserve the right to stop the journey as it is not good for
            either the chauffeur or the car for prolonged journeys.
            </p>

            <p>
            Our chauffeurs must be treated with respect, dignity and humility. Abusing them in
            any manner might lead to legal action against the customer or any person involved in
            such abuses along with Customer.
            </p>

            <p>
            In case of any questions or complaints please reach out to us at our website
            www.rnk.com or call us on 022-43227799.
            </p>

            <p>
            Customer is strictly prohibited from insisting upon driving the vehicle in presence of
            the chauffeur and/or otherwise when services of chauffeur have been availed by
            customer.
            </p>
        </div>

        <div className='replacement-vehicle'>
            <h5>Replacement of Vehicle</h5>
            <p>
            Replacement Vehicle shall be provided within a city limits only (Subject to availability
            of similar segments in the city). This is only for such cases where a Vehicle develops
            mechanical error and restrain the User from its use. In a case, where a Vehicle
            develops such error outside the city limit, replacement Vehicle will not be provided
            and amount equivalent to agreed rental will be charged. Company’s decisions related
            to replacement vehicles will be final.
            </p>
        </div>

        <div className='idemnity'>
            <h5>Indemnity</h5>
            <p>
            The Customer/User hereby indemnifies and agrees to indemnify the Company in
            future from and against any claim for loss or for damage to any property (including
            costs relating thereto) left, stored or transported by the Customer/User or any other
            person in or upon the Vehicle before or after return of the Vehicle.
            </p>

            <p>
            The User shall be solely responsible for and shall keep the Company fully
            indemnified against any loss, theft, damage, costs, accident or injury (including
            death) to persons or property, including loss, theft, damage, accident of the Vehicle,
            accruing in connection with the Vehicle or as a result of the negligent use thereof and
            against any breach of obligation by the user of the Vehicle.
            </p>

            <p>
            The User shall keep the Company fully indemnified including its directors, employees
            and shareholders against all losses, liabilities, costs actions, claims, any fines or
            penalties imposed (Losses) in respect of these Terms and Conditions or arising out
            of or in connection with use of the Vehicle including Losses on account of any third
            party liability, damage to the vehicle, accidents, illegal use of the Vehicle,
            misrepresentation or breach of its obligations by the User.
            </p>

            <p>
            The User hereby acknowledge that for the purposes of the Motor Vehicles Act, 1988,
            the User shall be liable in respect of the Vehicle for any of the offences which may be
            committed under the applicable law and for any liability with respect to the use of the
            Vehicle including but not limited to any challans, penalties, third party liability,
            accidents and the User hereby indemnifies and shall keep the Company indemnified
            against any losses, damages, costs and liabilities etc., in this regard.
            </p>
        </div>

        <div className='desclaimer'>
            <h5>Disclaimer</h5>
            <p>
            The User acknowledges that the use of the Services is at his sole risk. The Company
            disclaims all representations and warranties of any kind, whether express or implied
            as to condition, suitability, quality, merchantability and fitness of the Services offered
            by it.
            </p>

            <p>
            The Company shall not be responsible or liable for any loss or damage, however
            caused or suffered by the User arising out of the use of the Services or due to
            Company’s failure to provide the Services at all, for any reason whatsoever whether
            or not beyond the control of the Company.
            </p>
        </div>

        <div className='self-drive'>
            <div className='self-data'>
            <h2>SELF DRIVE</h2>
            <h5>Eligibility</h5>
            <p>
            Minimum age to drive our cars is 30 years
            Should have at least 7 years of having a valid driver's license.
            </p>
            </div>

            <div className='parking-process'>
            <h5>Booking Process</h5>
            <p>
            We request you to provide Driver License and Aadhar copies by email to us at the time of
            booking itself. The customer is expected to provide copies of Aadhar card, and valid driving
            licence in order to book the vehicle, in case of Indian Nationals. In case of Foreign Nationals,
            they should provide an International driving permit, residence proof of stay in India and copy
            of Passport. We understand and insist that the customer/person who has done car booking
            with us is the customer/person using our car therefore the customer in whose name the
            booking has been made is solely responsible for consequences, if arisen for allowing anyone
            else to use/drive our car (other than him/her in whose name the hiring is done) especially
            Children below 18 years of age and Car keys are strictly to be kept away from them. RNK
            considers it to be a breach of contract and shall not be responsible for any claims made by
            the customer to that effect.<br /> <br />
            We don’t allow car pickups and drop off from our garages for security reasons. Our
            Operations crew will pick up and deliver and this service is chargeable and non negotiable. <br />
            Hiring Prices are calculated on the basis of destination discussed at the time of booking. Any
            changes to be made after this need to be shared with us is advance and said changes might
            attract additional charges and may differ with other RTO regulations and customer shall be
            responsible to bear the same. <br /> <br />
            Customer is allowed to drive car at the given speed for 150Kms per day however every
            kilometre above 150 kms shall be at an additional charge at extra cost. <br />
            Interest Free Security Deposit will be charged on self-drive cars. The deposit amounts will
            vary based on the car model and shall be informed to you at the time of booking. The refund
            of the deposit may take up to 2 working days after the complete evaluation of the car and its

            GPS tracking as per the system. In case any damage is observed while evaluating the car,
            the same shall be to the account of the customer and refund of security deposit may take
            additional time. If the car is used at over speed or is damaged or customer has entered into
            any kind of negotiations with any third party for undue incidence, the period for balance
            payment shall automatically get extended as the company will have to get necessary
            clearances from authorities and operations. <br />
            </p>
            </div>
            <div className='customer-responsibilities'>
                <h5>Customer Responsibilities</h5>
                <p>
                · It is the customer's responsibility to inspect the vehicle and report/record damages at the
                time of taking the delivery of the car. Any complaints later on will not be entertained by
                Company.<br /><br />

                · All cars are insured bumper to bumper for the safety of the individual, third party and the
                vehicle and the insured amount varies from vehicle to vehicle. But usually it is high.<br /><br/>

                · Any damages, internal or external, will be first recovered from the customer's safety deposit
                and if it exceeds, customers will have to pay an additional amount. The decision to apply for
                Insurance in such matters lies with the Company and customer <span className='indemnity-span'> has no authority to interfere
                in that matter. You as a customer agree to this condition.</span><br /><br />

                · Maximum speed legally allowed is 100Kms/Hr and over speeding may result in a penalty of
                Rs. 1500/- each offence viz. each time car speed has been breached by customer. <br /> <br />

                · No Alcohol, Drinking or Smoking Allowed inside our Car especially Luxury Fleet. If Car is not
                returned in a clean condition, cleaning charges will be applicable in addition to addition to
                additional fine for inappropriate use of Car and damages, if <span className='indemnity-span'> Company decides.</span><br /> <br />

                · All local Taxes, Tolls, Parking Tickets, Traffic Violations are paid by the customer. Any
                serious traffic violations may attract an additional fine for jeopardising the public safety
                Fuel to be borne by the customer. All Rides are Full Tank to Full Tank <span className='indemnity-span'>basis.Fuel at actual
                will be charged if not Full at the time of return along with an additional charges/fine.</span><br /> <br />

                · Any damage related expenses will be completely borne by the customer at the time of
                return. It will be either deducted from the deposit or the excess amount (if any) will be billed
                to the customer after the trip. <br /> <br />

                · Causing damages will attract additional charges for downtime as the vehicle may become
                unusable for other customers, causing loss of revenue to the company. <br /> <br />

                · We require the complete address where the car will be parked at the time of booking itself in
                order to avoid any confusion of whatsoever nature at the time of delivery of the Car back to
                the Company (Street side parking is not allowed and will be <span className='indemnity-span'>detected by our GPS System
                and shall attract penalties)</span><br /><br />

                · Car can’t be abandoned on the road or anywhere unsafe. As mentioned herein before a
                complete address needs to be provided to the Company with respect to parking of Car and
                in the event of change of address of parking, the Company needs to be <span className='indemnity-span'> informed in advance
                and an approval needs to be taken from the Company. Failure too observe this may result in
                impounding the Security deposit and further legal action for negligent behaviour.</span><br /> <br />

                · Failing to return the car as per the agreed schedule shall attract “Run Time Penalty” which
                may include complete deduction of the entire safety deposit amount for causing loss of
                booking and hindrance in administrative work of the Company.<br /> <br />

                · Company does not allow Car/Vehicle to be used to carry pets, goods or any other object
                which can damage the upholstery/any part of the vehicles. In such an event, the Customer
                will be charged on the actual cost towards repairing/cleaning of <span className='indemnity-span'> interior / exterior of vehicle
                plus for loss of revenue to the Company for non usage of the car.</span><br /> <br />

                · Carrying illegal Firearms, ammunition, drugs or any illegal substance in Car is strictly
                prohibited. Customer is further prohibited from carrying out any other illegal activity/ies in the
                Car. The car cannot be used for towing, giving driving lessons or <span className='indemnity-span'> teaching driving to others,
                racing, speed test, leasing out to others, use for commercial purposes.</span> <br /> <br />

                · Customer should not carry more passengers than may be properly accommodated by the
                seat belt restraints in the Vehicle or carry a load greater than for which it was built. Should
                not carry more passengers than may be Authorised by the road <span className='indemnity-span'> transport authority in the
                registration book of the Vehicle.</span><br /> <br />
                </p>
            </div>

            <div className='replacement'>
                <h5>Replacement of Vehicle</h5>
                <p>
                Replacement of Vehicle shall be provided within a city limits only (Subject to availability of
                similar segments in the city). This is only for such cases where a Vehicle develops
                mechanical error and restrain the User from its use. In a case, where a Vehicle develops
                such an error outside the city limit, replacement Vehicle will not be provided however amount
                equivalent to agreed rental will still be charged. Company decisions related to replacement
                vehicles will be final.
                </p>
            </div>

            <div className='indemnity'>
                <h5>Indemnity</h5>
                <p>
                · The Customer/User hereby indemnifies and agrees to indemnify the Company in future from
                    and against any claim for loss or for damage to any property (including costs relating
                    thereto) left, stored or transported by the Customer/User or any other <span className='indemnity-span'> person in or upon the
                    Vehicle before or after return of the Vehicle.</span> <br />

                · The User shall be solely responsible for and shall keep the Company fully indemnified
                against any loss, theft, damage, costs, accident or injury (including death) to persons or
                property, including loss, theft, damage, accident of the Vehicle, accruing in <span className='indemnity-span'> connection with
                the Vehicle or as a result of the negligent use thereof and against any breach of obligation
                by the user of the Vehicle.</span> <br />

                · The User shall keep the Company fully indemnified including its directors, employees and
                shareholders against all losses, liabilities, costs actions, claims, any fines or penalties
                imposed (Losses) in respect of these Terms and Conditions or arising out of <span className='indemnity-span'> or in connection
                with use of the Vehicle including Losses on account of any third party liability, damage to the
                vehicle, accidents, illegal use of the Vehicle, misrepresentation or breach of its obligations by
                the User.</span><br />

                · The Customer/User hereby acknowledge that for the purposes of the Motor Vehicles Act,
                    1988, the Customer/User shall be liable in respect of the Car/Vehicle (belonging to the
                    Company and hired by the Customer from the Company) for any of the <span className='indemnity-span'> offences which may
                    be committed under the applicable law. The Customer shall also be liable with respect to the
                    use of the Vehicle including but not limited to any challans, penalties, third party liability,
                    accidents etc., happened due to and the <span className='indemnity-span'> Customer/User hereby indemnifies and shall keep
                    the Company indemnified against any losses, damages, costs and liabilities etc., in this
                regard. </span> </span><br />
                </p>
            </div>

            <div className='other-condition'>
                <h5>Other Conditions and additional Charges</h5>
                <p>
                · Night charges will be chargeable as applicable. <br />
                · GST will be applicable, as per Govt. directive.
                · All Interstate Taxes, Permit fee, Toll, and Parking etc. shall be extra on actual. <br />
                · Disputes if any would be resolved as per the Indian Arbitration Act before single. <br />
                . Arbitrator as per Indian Laws and place of Arbitration shall be Mumbai and Customer
                  shall have no right to seek any changes in the same. <br />
                · Other terms and conditions may additionally apply as applicable on a case to case
                  basis. <br />
                </p>
            </div>

            <div className='disclaimer'>
                <h5>Disclaimer</h5>
                <p>
                The User acknowledges that the use of the Services is at his sole risk. The Company
                disclaims all representations and warranties of any kind, whether express or implied as to
                condition, suitability, quality, merchantability and fitness of the Services offered by it.<br />

                Company shall not be responsible or liable for any loss or damage however caused or
                suffered by the Customer/User arising out of the use of the Services or due to Company’s
                failure to provide the Services at all, for any reason whatsoever whether or not beyond the
                control of the Company.

                </p>
            </div>
        </div>

        <div className='genral-terms'>
            <h2>GENERAL TERMS AND CONDITIONS</h2>
            <h5>Eligibility</h5>
            <p>
            Should be of minimum 18 years and above. - Children below the age of 18 can&#39;t
            travel alone and must be accompanied by an adult at all times.
            </p>
            <p>
            For Self Driven cars, refer the self-drive terms and conditions.
            </p>
        </div>

        <div className='booking-process'>
            <h5>Booking Process:</h5>
            <p>
            Cars are issued to the person who contacts and sends details to the Company. If at
            any point in time we find that the car is being driven by a different person (self-driven
            car) or used for a different purpose, we may cancel the trip abruptly and recall the car
            without any refunds.
            </p>
            <p>
            We reserve the right to refuse bookings or on-boarding of customer without any
            explanation as per our safety and security standards even if the booking has been
            received.
            </p>
            <p>
            Customer name used in the Booking invoice will finally be responsible for all T&amp;C
            mentioned here in order to return the car in the condition given.
            </p>
            <p>
            There may be times when a technical error may occur at the time of booking of Car.
            During such times the values/ prices/ details of the fleet may be completely different
            from the actual ones, in such cases if there is any booking made by the customer on
            such fleet will be cancelled immediately with complete refund within ___ working
            days.
            </p>
        </div>

        <div className='cancle'>
        <h5>Cancellations</h5>
        <p>
        Any cancellation/modification to be informed only via email between 10am to 7pm on
        all working days and minimum 4 hours prior to the scheduled time.
        </p>
        <p>
            Deduction in deposit upon cancellation shall be in the following manner for Cars
            other than Luxury Cars;
        </p>

        <p>
            1. Prior to 48 hours from pick-up time 0%
        </p>
        <p>
            2. Within 24 – 48 hours from pick-up time 30%
        </p>
        <p>
            3. Within 4 – 24 hours from pick-up time 50%
        </p>
        <p>
            4. Within 0 – 4 hours from pick-up time 100%
        </p>
        <p>
            5. No show - 100%
        </p>
        <p>
        Cancellation of Luxurious Cars is not permitted at any time within 48 hours, since
        such cancellation amounts loss of revenue to the Company and the Company shall
        forfeit entire amount of deposit. However cancellation prior to 48 hours from pick-up
        time shall attract 0% deduction.
        </p>
        <p>
        Payment Gateway charges are not refunded on any platforms for any type of booking
        cancellations.
        </p>
        <p>
        Reservations need to be cancelled or modified through email to the official email ID
        only and not by any other mode.
        </p>
        </div>

        <div className='customer-responsible'>
            <h5>Customer Responsibilities</h5>
            <p>
            All of our chauffeurs are COVID19 vaccinated and have been trained on professional
            etiquettes and standards of customer interaction.
            </p>
            <p>
                All cars are subject to availability and in as is condition.
            </p>
            <p>
            We usually have a good mix of latest and previous generation cars in the fleet. We
            strive to send you the latest editions based on availability but can&#39;t guarantee new or
            latest models always and Customer can not make any complaint against the
            Company for no provision of latest model of Car. In the event of specific requirement
            of Car, Customer has not notify the Company about his/her specific requirement for
            certain models in advance and the Company shall make all endeavours to make the
            same available to the Customer, however in the event such services are not
            available then the Company reserves its right to cancel the ride with specific
            requirements and intimate the Customer accordingly.
            </p>
            <p>
            Safety of our vehicle, chauffeur and passenger is utmost important to us. In case we
            feel the contractual terms or safety standards are compromised for whatever reason,
            we may recall the car and chauffeur unconditionally. Refunds are subject to further
            evaluation of the situation.
            </p>
            <p>
            In case of any communal riots, natural disaster or any other threats that our
            chauffeurs encounter during the trip, we may call back the car without prior notice to
            the customer and/or may not provide services at all.
            </p>
            <p>
            In case the car is used for any illegal activities, the customer will be completely
            responsible for the entire issue including the compensation to us.
            </p>
            <p>
            Any damages caused or violation of traffic rules may result in profuse delay in
            refunds.
            </p>
        </div>

        <div className='replace-vehicle'>
            <h5>Replacement of Vehicle</h5>
            <p>
            Replacement Vehicle shall be provided within a city limits only (Subject to availability
            of similar segments in the city). This is only for such cases where a Vehicle develops
            mechanical error and restrain the User from its use. In a case, where a Vehicle
            develops such error outside the city limit, replacement Vehicle will not be provided
            and amount equivalent to agreed rental will be charged. Company decisions related
            to replacement vehicles will be final.
            </p>
        </div>

        <div className='indemnity'>
            <h5>Indemnity</h5>
            <p>
            The Customer/User hereby indemnifies and agrees to indemnify the Company in
            future from and against any claim for loss or for damage to any property (including

            costs relating thereto) left, stored or transported by the Customer/User or any other
            person in or upon the Vehicle before or after return of the Vehicle. <br /><br/>
            </p>

            <p>
            The User shall be solely responsible for and shall keep the Company fully
            indemnified against any loss, theft, damage, costs, accident or injury (including
            death) to persons or property, including loss, theft, damage, accident of the Vehicle,
            accruing in connection with the Vehicle or as a result of the negligent use thereof and
            against any breach of obligation by the user of the Vehicle.  <br /><br/>
            </p>

            <p>
            The User shall keep the Company fully indemnified including its directors, employees
            and shareholders against all losses, liabilities, costs actions, claims, any fines or
            penalties imposed (Losses) in respect of these Terms and Conditions or arising out
            of or in connection with use of the Vehicle including Losses on account of any third
            party liability, damage to the vehicle, accidents, illegal use of the Vehicle,
            misrepresentation or breach of its obligations by the User.  <br /><br/>
            </p>

            <p>
            The User hereby acknowledge that for the purposes of the Motor Vehicles Act, 1988,
            the User shall be liable in respect of the Vehicle for any of the offences which may be
            committed under the applicable law and for any liability with respect to the use of the
            Vehicle including but not limited to any challans, penalties, third party liability,
            accidents and the User hereby indemnifies and shall keep the Company indemnified
            against any losses, damages, costs and liabilities etc., in this regard.  <br /><br/>
            </p>
        </div>

        <div className='other-condition'>
            <h5>Other Conditions</h5>
            <p>Night charges will be chargeable as applicable.</p>
            <p>GST will be applicable, as per Govt. directive.</p>
            <p>All Interstate Taxes, Permit fee, Toll, and Parking etc. shall be extra as per actuals.</p>
            <p>Disputes if any would be resolved as per the Indian Arbitration Act.</p>
            <p>Other terms and conditions may additionally apply as applicable on a case to case basis</p>
        </div>

        <div className='disclaimer'>
            <h5>Disclaimer</h5>
            <p>
            The User acknowledges that the use of the Services is at his sole risk. The Company
            disclaims all representations and warranties of any kind, whether express or implied
            as to condition, suitability, quality, merchantability and fitness of the Services offered
            by it.
            </p>
            <p>
            The Company shall not be responsible or liable for any loss or damage, however
            caused or suffered by the User arising out of the use of the Services or due to
            Company’s failure to provide the Services at all, for any reason whatsoever whether
            or not beyond the control of the Company.
            </p>
        </div>
        <div className='note'>
            <h5>Note:</h5>
            <p>These T&C are constantly updated and the latest conditions are applied even if it is
            not available in the invoice at the time of booking.</p>
        </div>
    </div>
  )
}

export default TermsCondition;