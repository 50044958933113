import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import CarListing from '../pages/CarListing';
import CarDetails from '../pages/CarDetails';
import Blog from '../pages/Blog';
import BlogDetails from '../pages/BlogDetails';
import Contact from '../pages/Contact';
import LocalDuty from "../pages/LocalDuty";
import LocalCarBookingForm from "../pages/LocalCarBookingForm";
import HotelOperation from "../pages/HotelOperation";
import VipServices from "../pages/VipServices";
import Airport from "../pages/Airport";
import LongTermCarRent from "../pages/LongTermCarRent";
import LuxuryTravel from "../pages/LuxuryTravel";
import BookingPage from "../pages/BookingPage";
import Details from "../pages/Details";
import OurNetwork from "../pages/OurNetwork";
// import MercedesClass from "../pages/MercedesClass";
import PrivecyPolicy from "../pages/PrivecyPolicy";
import TermsCondition from "../pages/TermsCondition";
import NotFound from '../pages/NotFound';


const Routers = () => {
    return <Routes>
        <Route path="/" element={<Navigate to='/home' />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/Fleet" element={<CarListing />} />
        <Route path="/Fleet/:slug" element={<CarDetails />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/LocalDuty" element={<LocalDuty />} />
        <Route path="/LocalCarBookingForm" element={<LocalCarBookingForm />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path="/HotelOperation" element={<HotelOperation />} />
        <Route path="/VipServices" element={<VipServices />} />
        <Route path="/Airport" element={<Airport />} />
        <Route path="/LongTermCarRent" element={<LongTermCarRent />} />
        <Route path="/LuxuryTravel" element={<LuxuryTravel />} />
        <Route path="/BookingPage" element={<BookingPage />} />
        <Route path="/Details" element={<Details />} />
        <Route path="/OurNetwork" element={<OurNetwork />} />
        {/* <Route path="/MercedesClass" element={<MercedesClass />} /> */}
        <Route path="/PrivecyPolicy" element={<PrivecyPolicy />} />
        <Route path="/TermsCondition" element={<TermsCondition />} />
        <Route path="/*" element={<NotFound />} />
    </Routes>
}

export default Routers;