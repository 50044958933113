import React from "react";
import ReactDOM from 'react-dom';
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { HelmetProvider } from "react-helmet-async";


ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
    <CookieConsent
      location="bottom"
      buttonText= "I ACCEPT"
      declineButtonText="I DECLINE"
      declineButtonStyle={{
        'padding' : '12px 32px',
        'border-radius': '3px',
        'backgroundColor': 'black',
        'outline': 'none',
        'border': 'none',
        'color' : '#7c828d',
        'font-size' : '14px',
        'font-weight': '500'
      }}
      cookieName="myAwesomeCookieName2"
      buttonStyle={{
        'padding' : '12px 32px',
        'border-radius': '3px',
        'backgroundImage': 'linear-gradient(68deg, #72be44, #00a0fd)',
        'outline' : 'none',
        'color' : 'white',
        'font-size' : '14px',
        'font-weight': '500',
        'border' : '1px solid transparent',
        'border-image' : 'linear-gradient(89deg, #72be44, #00a0ffd)',
        'border-image-slice' : '1',
      }}
      expires={50}
      enableDeclineButton
>
  This website uses cookies to enhance the user experience.
</CookieConsent>
    <Router>
      <App />
    </Router>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);