
import React from "react";
import Slider from "react-slick";
import '../../styles/testimonial.css';

const Testimonial = () => {

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        swipeToSlide: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return <Slider {...settings}>

        <div className="testimonial py-4 px-3">
            <p className="section__description">
            I appreciate the attention to detail and the fact that they go above 
            and beyond to ensure everything is just right. I highly recommend 
            this luxury car rental service to anyone needing top-notch transportation.
            </p>

            <div className="mt-3 d-flex align-items-center gap-4">
            </div>

            <div>
                <h6 className="mb-0 mt-3">Vallabhdad Lalchand</h6>
            </div>

        </div>

        <div className="testimonial py-4 px-3">
            <p className="section__description">
            They make travel a breeze, and I would highly recommend 
            them to anyone looking for a luxury car rental experience.
            </p>

            <div className="mt-3 d-flex align-items-center gap-4">
            </div>

            <div>
                <h6 className="mb-0 mt-3">Mr. Murali M Natrajan</h6>
            </div>

        </div>

        <div className="testimonial py-4 px-3">
            <p className="section__description">
            I recently used this luxury car rental service for a tour 
            around Mumbai, and the quality of the experience blew me away. 
            The driver was professional and courteous, and the vehicle 
            was in pristine condition
            </p>

            <div className="mt-3 d-flex align-items-center gap-4">
            </div>

            <div>
                <h6 className="mb-0 mt-3">Mr. Farokh Subedar</h6>
            </div>

        </div>

        <div className="testimonial py-4 px-3">
            <p className="section__description">
                It was a great day out. None of us can drive and are thus restricted when we travel, 
                but RNK is the best solution! Our chauffeur was the best.
            </p>

            <div className="mt-3 d-flex align-items-center gap-4">
            </div>

            <div>
                <h6 className="mb-0 mt-3">Mr. Chittranjan Thakkar</h6>
            </div>

        </div>
    </Slider>
}

export default Testimonial;