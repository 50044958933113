import React, { useState } from "react";
import Slider from "react-slick";
import { Container } from "reactstrap";
import "../../styles/hero-slider.css";
import { useNavigate } from 'react-router-dom';


function HeroSlider() {
    const navigate = useNavigate();
    const settings = {
        // fade: true,
        // speed: 2000,
        // autoplaySpeed: 3000,
        // infinite: true,
        // autoplay: true,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        // pauseOnHover: false,
    };

    /** show the input data to the welcome page */
    const [formData, setFormData] = useState({});
    const [submittedData, setSubmittedData] = useState(null);

    /* this code is used to store the form data to the google sheet  */
    const [data, setData] = useState({
        name: "",
        email: "",
        phone: "",
        city: "",
        vehicle: "",
        date: "",
        duty: "",
    });

    const { name, phone, email, city, vehicle, date, duty } = data;

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value });
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmittedData(formData);

        setTimeout(() => {
            setSubmittedData(null);
        }, 5000);

        try {
            const response = await fetch("https://v1.nocodeapi.com/anshujha1999/google_sheets/zSYoYPtpSNndkrCD?tabId=LocalCarBookingData", {
                method: 'POST',
                headers: {  
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([[name, phone, email, city, vehicle, date, duty, new Date().toLocaleString()]])
            });
            await response.json()
            setData({ ...data, name: "", phone: "", email: "", city: "", vehicle: "", date: "", duty: "" })

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Slider {...settings} className="hero__slider">
            <div className="slider__item slider__item-01">
                <button onClick={() => { navigate('/LocalCarBookingForm') }} className="booking__form-page">BOOK YOUR CAR ➔</button>
                <Container>
                    <div className="container__form">
                        <form className="form__details" onSubmit={handleSubmit}>
                            <div className="form first">
                                <div className="details__personal">
                                    <h2 className="title">Book Your Car</h2>

                                    <div className="feilds">
                                        <div className="input-feild">
                                            <input type="text" name='name' id='name' placeholder='Name' required autocomplete="of"
                                                value={name} onChange={handleChange} />
                                        </div>

                                        <div className="input-feild">
                                            <input type="tel" name="phone" id="phone" placeholder="Phone" required autocomplete="of"
                                                value={phone} onChange={handleChange} />
                                        </div>

                                        <div className="input-feild">
                                            <input type="email" name="email" id="email" placeholder="Email" required autocomplete="of"
                                                value={email} onChange={handleChange} />
                                        </div>

                                        <div className="input-feild">
                                            <input type="text" name="city" id="city" placeholder="City" required autocomplete="of"
                                                value={city} onChange={handleChange} />
                                        </div>

                                        <div className="input-feild">
                                            <input type="text" name="vehicle" id="vehicle" placeholder="Vehicle Type" required autocomplete="off"
                                                value={vehicle} onChange={handleChange} />
                                        </div>

                                        <div className="input-feild">
                                            <input type="date" name="date" id="date" placeholder="Date" required autocomplete="off"
                                                value={date} onChange={handleChange} />
                                        </div>

                                        <div className="input-feild">
                                            <input type="text" name="duty" id="duty" placeholder="Duty Type" required autocomplete="off"
                                                value={duty} onChange={handleChange} />
                                        </div>

                                        <div className="input-feild-btn">
                                            <button type="submit">Book Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {
                            submittedData && (
                                <div className="all__value-data">
                                    <h6>Dear {submittedData.name}</h6>
                                    <span>Thank you for contacting RNK Booking team will connect you very soon!</span>
                                    <ul>
                                        <li>Name:{submittedData.name}</li>
                                        <li>Phone:{submittedData.phone}</li>
                                        <li>Email:{submittedData.email}</li>
                                        <li>City:{submittedData.city}</li>
                                        <li>Car Type:{submittedData.vehicle}</li>
                                        <li>Booking Date:{submittedData.date}</li>
                                        <li>Duty Type:{submittedData.duty}</li>
                                    </ul>
                                </div>
                            )
                        }
                    </div>

                    <div className="mobileScreen__button">
                        <button onClick={() => { navigate('/BookingPage') }} className="btn__mobile">Book Your Car</button>
                    </div>
                </Container>
            </div>
        </Slider>
    );
};

export default HeroSlider;