export const navItems = [
    {
        id: 1,
        title: "HOME",
        path: "/home",
        cName: "nav-item",
    },

    {
        id: 2,
        title: "ABOUT US",
        path: "/about",
        cName: "nav-item",
    },

    {
        id: 3,
        path: "/fleet",
        title: "FLEET",
        cName: "nav-item",
    },

    {
        id: 4,
        //path: "/services",
        title: "SERVICES",
        cName: "nav-item",
    },

    {
        id: 5,
        path: "/OurNetwork",
        title: "OUR NETWORK",
        cName: "nav-item",
    },

    {
        id: 6,
        path: "/contact",
        title: "CONTACT US",
        cName: "nav-item",
    },
];

export const serviceDropdown = [
    {
        id: 1,
        title: "Local Duty",
        path: "/localduty",
        cName: "submenu-item",
    },

    {
        id: 2,
        title: "Hotel Operation / Travel Desk",
        path: "/hoteloperation",
        cName: "submenu-item",
    },

    {
        id: 3,
        title: "VIP Service",
        path: "/vipservices",
        cName: "submenu-item",
    },
    
    {
        id: 4,
        title: "Airport / Railway Station Transfer",
        path: "/airport",
        cName: "submenu-item",
    },

    {
        id: 5,
        title: "Long Term Car Rent",
        path: "/longtermcarrent",
        cName: "submenu-item",
    },

    {
        id: 6,
        title: "Outstation Travel",
        path: "/luxurytravel",
        cName: "submenu-item",
    },
];


export const fleetDropdown = [
    {
        id: 1,
        title: "Economy",
        path: "/luxurycar",
        cName: "submenu-item",
    },

    {
        id: 2,
        title: "Executive",
        path: "/executive",
        cName: "submenu-item",
    },

    {
        id: 3,
        title: "SUV/MUVs",
        path: "/suvmuvs",
        cName: "submenu-item",
    },

    {
        id: 4,
        title: "LUXURY",
        path: "/luxury",
        cName: "submenu-item",
    },
];