import React from 'react';
import "../styles/vip-services.css";
import { useNavigate } from 'react-router-dom';


function VipServices() { 
    const navigate = useNavigate();

    return (
        <div className='vip__operation-container'>
            <div className='vip__operation-page'>
            </div>
            <div className='vip__title'>
                VIP Travel
                <p>
                    Secure and stress-free commute for the VIPs.
                </p>
            </div>
            <div className='vip__slide-container'>
                <p>
                    At <span> RNK - RAMNIRANJAN KEDIA </span> Group, we offer luxury rental cars that provide a
                    higher level of safety and security, with advanced safety features and high-quality
                    construction. Our fleet of top-of-the-line vehicles offer a wide range of options, each
                    one meticulously maintained to ensure the highest quality and performance
                    standards. We provide customised amenities as per your requirement and latest
                    vehicles. From sleek sedans and spacious SUVs, our collection of luxury vehicles is
                    designed to cater to your every need and desire. With our personalized service and
                    attention to detail, you can trust that your rental experience with us will be nothing
                    short of exceptional, all suited for high VIP standards.
                </p>

                <div className='vip__services'>
                    <h1>Our Special Services-</h1>
                    <p>
                        We offer highly secure luxury car rides for VIPs to help them reach their
                        destination with utmost comfort and ease. <br/><br/>

                        We have 50 years + experience in catering to VIPs and expats from different
                        countries. We offer various luxury cars on rent- from BMW, and Audi to
                        Mercedes, you can select from the top luxury vehicles.
                        <button onClick={() => { navigate('/fleet') }} className="link__fleet-feild">Click Here</button> to check our entire fleet<br/><br/>

                        We offer rental cars for a group of people that might accompany the VIP.
                    </p>
                <div className='vip__experience'>
                    <h1>We have decades of experience in serving VIPs and expats</h1>
                    <p>
                        Whether you are a high-profile celebrity, a corporate executive, or someone who
                        simply enjoys the finer things in life, we are here to provide you with the ultimate
                        driving experience.<br/><br/>

                        So sit back, relax, and let us take care of the rest as you hit the road in style and
                        luxury. Call us now to book a ride!
                    </p>

                <div className='vip__rnk'>
                    <h1>Why RNK?</h1>
                    <p>
                        If luxury is what you are looking for - you are at the perfect place. But, RNK offers
                        more than just premium cars. Here is why you should choose our services -
                    </p>
                <div className='vip__chauffer'>
                    <h1>Chauffeur-driven luxury</h1>
                    <p>
                        When you rent a chauffeur-driven luxury car, you don't have to worry about driving
                        and navigating unfamiliar roads or traffic. A professional chauffeur can handle all
                        aspects of the ride, allowing you to relax, work, or enjoy the scenery.
                    </p>
                    
                <div className='vip__gps'>
                    <h1>GPS-enabled</h1>
                    <p>
                        A GPS-enabled luxury car is secure and can help you easily navigate roads in
                        unknown locations. This can be particularly helpful if you&'re travelling to a new city or
                        country and need to find your way around quickly and efficiently.
                    </p>
                </div>

                <div className='vip__security'>
                    <h1>Security</h1>
                    <p>
                    A secure luxury car prioritizes the safety of its customers by providing well-
                    maintained and reliable vehicles. This can help in making sure that you arrive at your
                    destination safely and on time.
                    </p>
                </div>
                <div className='vip__comfort'>
                    <h1>Comfort fit for VIPs</h1>
                    <p>
                    The vehicles are equipped with features such as plush seating, climate control, and
                    advanced sound systems, allowing you to ride conveniently.
                    </p>
                </div>
                <div className='vip__extensive'>
                    <h1>Extensive fleet of luxury cars</h1>
                    <p>
                    With more options, you can select a luxury car that is most suitable for your specific
                    occasion or event. We have various luxury vehicles such as BMW, Audi, Mercedes
                    and so on.
                    </p>
                </div>
                <div className='vip__support'>
                    <h1>24/7 support on-call</h1>
                    <p>
                    You will get immediate assistance and help quickly resolve the issue, ensuring your
                    travel plans are maintained.
                    </p>
                </div>
                </div>
                </div>
                </div>
                </div>
            </div>
           
            <div className="accordion">
                <div className='accordion__title'>FAQs</div>
                <div>
                    <input type="checkbox" name='example__accordion' id='section1' className='accordion__input' />
                    <label htmlFor="section1" className='accordion__label'>Do you perform a background check for the staff that's going to serve the VIPs?</label>
                    <div className='accordion__content'>
                        <p>
                            Yes. All our staff passes a background verification. Our chauffeurs are well-
                            trained English Speaking and have a lot of experience in serving very important
                            clients.
                        </p>
                    </div>
                </div>
                <div className='main__accordion'>
                <div>
                    <input type="checkbox" name='example__accordion' id='section2' className='accordion__input' />
                    <label htmlFor="section2" className='accordion__label'>Do you provide any special services for the VIPs?</label>
                    <div className='accordion__content'>
                        <p>
                            Yes, we offer special luxury cars with plush seating and advanced technology to
                            provide a riding experience that a VIP deserves. For VIPs we provide our senior most
                            chauffeur who can communicate in English too and customized amenities as per
                            requirement.<br/>
                            All VIP movements are closely monitored by our senior supervisors to ensure the
                            best service
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default VipServices;
