/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import Helmet from "../components/Helmet/Helmet";
import "../styles/contact.css";

const Contact = () => {

    const [data, setData] = useState({
        name: "",
        email: "",
        phone: "",
        city: "",
        discription: "",
    });
    const { name, email, phone, city, discription } = data;

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch("https://v1.nocodeapi.com/anshujha1999/google_sheets/zSYoYPtpSNndkrCD?tabId=contactData", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([[name, email, phone, city, discription, new Date().toLocaleString()]])
            });
            await response.json()
            setData({ ...data, name: "", email: "", phone: "", city: "", discription: "" })

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Helmet title="Contact Us">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.156983294441!2d72.
                            82346976469638!3d19.012802987124317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cebe38fa261d%3A0xcd8e08cae63ad0da!2sCentury%20Bazaar%2C%20Prabhadevi%2C%20Mumbai%2C%20Maharashtra%20400025!5e0!3m2!1sen!2sin!4v1675680845771!5m2!1sen!2sin"
                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>
            <section>
                <Container>
                    <Row>
                        <Col lg="7" md="7">
                            <div className='contact__detail-form'>
                            <div className="heading__title">
                                <h6>Get In Touch</h6>
                            </div>
                            <Form onSubmit={handleSubmit}>
                                <FormGroup className="contact__form">
                                    <input type="text" name='name' id='name' placeholder='Enter Your Name' className='boxes__input' required autocomplete="off"
                                        value={name} onChange={handleChange} />
                                </FormGroup>

                                <FormGroup className="contact__form">
                                    <input type="email" name='email' id='email' placeholder='Enter Your Email' className='boxes__input' required autocomplete="off"
                                        value={email} onChange={handleChange} />
                                </FormGroup>

                                <FormGroup className="contact__form">
                                    <input type="phone" name='phone' id="phone" placeholder='Enter Your Number' className='boxes__input' required autocomplete="off"
                                        value={phone} onChange={handleChange} />
                                </FormGroup>

                                <FormGroup className="contact__form">
                                    <input type="city" name='city' id='city' placeholder='Enter Your City' className='boxes__input' required autocomplete="off"
                                        value={city} onChange={handleChange} />
                                </FormGroup>

                                <FormGroup className="contact__form">
                                    <input type="discription" name='discription' id='discription' placeholder='Requirement' className='boxes__input' required autocomplete="off"
                                        value={discription} onChange={handleChange} />
                                </FormGroup>
                                <button className="contact__btn" type="submit">
                                    BOOK NOW
                                </button>
                            </Form>
                            </div>
                        </Col>

                        <Col lg="5" md="5">
                            <div className="contact__info">
                                <div className='title__heading'>
                                    <h2>Contact Us</h2>
                                </div>
                                <div className='address__title'>
                                    <p>
                                        Suite 19,<br />
                                        Kaliandas Udyog Bhavan,
                                        Century Bazar, Prabhadevi, Mumbai,  400025
                                    </p>

                                </div>

                                <div className="phone__logo">
                                    <i> For Enquiry (24×7) :  +91-22 43227799</i>
                                </div>

                                <div className="gmail__logo">
                                    <span> <a href="mailto:info@rnk.com">
                                        </a> info@rnk.com</span>
                                </div>

                                <div className="social__link">
                                    <h3 className>Follow Us On</h3>
                                    <a href="https://www.facebook.com/" target="_blank" rel="noreferrer" >
                                        <i class="ri-facebook-box-fill"></i>
                                    </a>

                                    <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                                        <i class="ri-instagram-line"></i>
                                    </a>

                                    <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
                                        <i class="ri-youtube-fill"></i>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet>
    );
};
export default Contact;
