/* eslint-disable no-unused-vars */
import React from "react";
import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import FindCarForm from "../components/UI/FindCarForm";
import AboutSection from "../components/UI/AboutSection";
import ServiceList from "../components/UI/ServiceList";
import { carData } from "../assets/data/carData";
import CarItems from "../components/UI/CarItems";
//import BecomeDriverSection from "../components/UI/BecomeDriverSection";
import Testimonial from "../components/UI/Testimonial";
import BlogList from "../components/UI/BlogList";
import Cards from "../components/UI/cards";
import StepSection from "../components/UI/StepSection";
//import OurClients from "../components/UI/OurClients";
import "../styles/home.css";

const Home = () => {
    return (
        <Helmet title="Home">

            {/* ============================= hero section ============================ */}
            <section className="p-0 hero__slider-section">
                <HeroSlider />

                <div className="hero__form">
                    <Container>
                        <Row className="form__row">
                            <Col lg='4' md='6' className="mb-5 text-center">
                                <div className="find__cars-left">
                                    {/* <h2>Book Your Car</h2> */}
                                </div>
                            </Col>

                            <Col lg='8' md='8' sm='12'>
                                <FindCarForm />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
            {/* ============================= about section ============================ */}

            <AboutSection />

            {/* ============================= SERVIE PRODUCTION ============================ */}
            <section>
                <Container>
                    <Row>
                        <Col lg='12' className="mb-5 text-center-service">
                            <h2 className="section__title-service">OUR SERVICES</h2>
                        </Col>
                        <Cards />
                    </Row>
                </Container>
            </section>

            {/* ============================= carItems section ============================ */}

            <section>
                <Container>
                    <Row>
                        <Col lg="12" className="text-center mb-5">
                            <h2 className="section__title-car">Pick Your Ride!</h2>
                            <p className="car__price">Our ride starting from Rs.1400. For more information please contact us.</p>
                        </Col>

                        {carData.slice(0, 6).map((item) => (
                            <CarItems item={item} key={item.id} />
                        ))}
                    </Row>
                </Container>
            </section>

            {/* ============================= carItems section ============================ */}

            <section>
                <Container>
                    <Row>
                        <Col lg="13" className="text-center mb-5">
                            <h2 className="section__title-setpSection">How We Work</h2>
                            <p className="work__step">Simple. Smooth. Seamless.</p>
                        </Col>
                    </Row>
                    <StepSection />
                </Container>
            </section>
            {/* ============================= Blog section ============================ */}

            <section>
                <Container>
                    <Row>
                        <Col lg='12' className="text-center mb-4">
                            <h2 className="section__subtitle-blog">Blogs and Insights</h2>
                        </Col>
                        <BlogList />
                    </Row>
                </Container>
            </section>

            {/* ============================= Testimonial section ============================ */}
            <section>
                <Container>
                    <Row>
                        <Col lg='12' className=" text-center mb-4">
                            <h2 className="section__subtitle-Client">What Our Clients Say</h2>
                        </Col>
                        <Testimonial />
                    </Row>
                </Container>
            </section>
        </Helmet>

    );
};

export default Home;