import React, { useState } from "react";
import { Container, Row } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import CarItem from "../components/UI/CarItems";
import carData from "../assets/data/carData";
import "../styles/car-listing.css";

const CarListing = () => {

    //[THIS CODE IS RELETED TO SHOW MORE CARS]
    const [numOfElement, setnumOfElement] = useState(6);
    const loadMore = () => {
        setnumOfElement(numOfElement + numOfElement);
    }
    const slice = carData.slice(0, numOfElement);
    return (
        <Helmet title="Cars">
            <CommonSection />
            <section>
                <Container>
                    <Row>
                        {slice.map((item) => (
                            <CarItem item={item} key={item.id} />
                        ))}
                    </Row>
                </Container>
                <button className="btn-load" onClick={() => loadMore()}>
                    Load More
                </button>
            </section>
        </Helmet>
    );
};

export default CarListing;