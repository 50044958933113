import React from 'react';
import "../styles/hotle-operation.css";
import { useNavigate } from 'react-router-dom';
import Helmet from '../components/Helmet/Helmet';



function HotelOperation() {
    const navigate = useNavigate();

    return (
        <Helmet title="Hotel Operation">
        <div className='hotel__operation-container'>
            <div className='hotel__operation-page'>
            </div>
            <div className='hotel__title'>
                Hotel Travel Desk 
                <p>
                    Comfort and luxury like never before!
                </p>
                </div>

            <div className='summary'>
                <p>
                    We have a variety of options for renting a car during your hotel stay or traveling to
                    and from the airport. Whether you're looking for a luxury vehicle for a special
                    occasion or a budget-friendly option for a family vacation, RNK is the perfect place.<br/><br/>

                    At <span>RNK - RAMNIRANJAN KEDIA</span>  Group, you will get hotel car rental services with all
                    the information and resources that you need to make the best choice for your travel
                    needs.<br/><br/>

                    Enjoy your trip in the style and comfort of a luxury car! You can travel in a chauffeur-
                    driven vehicle without compromising safety or traffic rules.
                </p>

                <div className='special__service'>
                <h1>Our Special Services-</h1>
                <p>
                        We have an on-call car rental service. You can book a luxury car of your choice
                        with the convenience of a call Your luxury car service is a phone call away.

                        We have a fleet of cars available for you. All of our luxury cars are well-
                        maintained and clean.<br/><br/>

                        We offer customisable packages to book car rides so that you always get the
                        best services at the best prices.<br/><br/>

                        We also offer intercity rides for large groups of people. You can include all your
                        guests and loved ones for a comfortable ride in a luxury car.</p>

                <div className='luxury__rent'>
                <h1>Luxury car rental to suit all your needs</h1>
                <p>
                    With flexible rental options and various features, you can find a luxury car on rent
                    that meets all your needs and exceeds your expectations.<br/>
                    Choose our services to provide your guests the ultimate service to and from your
                    hotel. Book a ride with us now!
                 <br/>
                </p>
                <div className='why__rnk'>
                    <h1>Why RNK?</h1>
                    <p>
                        If luxury is what you are looking for - you are at the perfect place. RNK offers more
                        than just premium cars. Here is why you should choose our services -
                    </p>
                </div>

                <div className='Chauffeur__driven'>
                    <h1>Chauffeur-driven luxury</h1>
                    <p>
                        When you rent a chauffeur-driven luxury car, you don't have to worry about driving
                        and navigating unfamiliar roads or traffic. A professional chauffeur can handle all
                        aspects of the ride, allowing you to relax, work, or enjoy the scenery.
                    </p>
                </div>

                <div className='gps__enabled'>
                    <h1>GPS-enabled</h1>
                    <p>
                        A GPS-enabled luxury car is secure and can help you easily navigate roads in
                        unknown locations. This can be particularly helpful if you're travelling to a new city or
                        country and need to find your way around quickly and efficiently.
                    </p>

                    <div className='Security'>
                        <h1>Security</h1>
                        <p>
                            A secure luxury car prioritizes the safety of its customers by providing well-
                            maintained and reliable vehicles. This can help in making sure that you arrive at your
                            destination safely and on time.
                        </p>
                    </div>

                    <div className='Comfort'>
                        <h1>Comfort fit for VIPs</h1>
                        <p>
                            The vehicles are equipped with features such as plush seating, climate control, and
                            advanced sound systems, allowing you to ride conveniently.
                        </p>
                    </div>

                    <div className='Extensive'>
                        <h1>Extensive fleet of luxury cars</h1>
                        <p>
                            With more options, you can select a luxury car that is most suitable for your specific
                            occasion or event. We have various luxury vehicles such as BMW, Audi, Mercedes
                            and so on.
                        </p>
                    </div>

                    <div className='support'>
                        <h1>24/7 support on-call</h1>
                        <p>
                            You will get immediate assistance and help quickly resolve the issue, ensuring your
                            travel plans are maintained.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="accordion">
                <div className='accordion__title'>FAQs</div>
                <div>
                    <input type="checkbox" name='example__accordion' id='section1' className='accordion__input' />
                    <label htmlFor="section1" className='accordion__label'>Is renting a luxury car at a local location cheaper in India?</label>
                    <div className='accordion__content'>
                        <p>
                        Renting a luxury car to travel locally in India is much cheaper as compared to
                        other countries.
                        </p>
                    </div>
                </div>
                <div className='main__accordion'>
                <div>
                    <input type="checkbox" name='example__accordion' id='section2' className='accordion__input' />
                    <label htmlFor="section2" className='accordion__label'>What luxury car models are available for rent for local travel?</label>
                    <div className='accordion__content'>
                        
                        <p>
                            At RNK luxury car rental services, you can find wide range of cars starting from
                            a standard sedan to high-end car models, including BMW&#39;s, Mercedes-Benz, Audi
                            and more. The specific models available may vary depending on the location and
                            availability. <button onClick={() => { navigate('/fleet') }} className="link__fleet-feild">Click Here</button> for our entire fleet
                        </p>
                    </div>
                </div>

                <div>
                    <input type="checkbox" name='example__accordion' id='section3' className='accordion__input' />
                    <label htmlFor="section3" className='accordion__label'>Can you take rental cars out of state in India?</label>
                    <div className='accordion__content'>
                        <p>
                            All our cars at RNK have all India permit and can be taken anywhere in India.
                        </p>
                    </div>
                </div>

                <div>
                    <input type="checkbox" name='example__accordion' id='section4' className='accordion__input' />
                    <label htmlFor="section4" className='accordion__label'>Are your luxury vehicles equipped with advanced security features?</label>
                    <div className='accordion__content'>
                        <p>
                        Absolutely. All our luxury vehicles are equipped with state-of-the-art security
                        features, including advanced alarm systems, GPS tracking, and secure locking
                        mechanisms, to safeguard against theft or unauthorized access.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </Helmet>
    )
}

export default HotelOperation;
