import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { useParams } from "react-router-dom";
import blogData from "../assets/data/blogData.js";
import Helmet from "../components/Helmet/Helmet";
import "../styles/blog-details.css";

const BlogDetails = () => {
    const { slug } = useParams();
    const blog = blogData.find((blog) => blog.title === slug);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [blog]);

    // sending the data to google sheet by using  code //
    const [data, setData] = useState({
        name: "",
        email: "",
        phone: "",
        city: "",
        comments: "",
    });
    const { name, email, phone, city, comments } = data;

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch("https://v1.nocodeapi.com/anshujha1999/google_sheets/VyfBrkRwVIzscrre?tabId=<LocalCarBookingData>", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([[name, email, phone, city, comments, new Date().toLocaleString()]])
            });
            await response.json()
            setData({ ...data, name: "", email: "", phone: "", city: "", comments: "" })

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Helmet title={blog.title}>
            <section>
                <Container>
                    <Row>
                        <Col lg="8" md="8">
                            <div className="blog__details">
                                <img src={blog.imgUrl} alt="" className="w-100" />
                                <h2 className="section__title-blog">{blog.title}</h2>

                                <div className="blog__publisher d-flex align-items-center gap-4 mb-4">
                                    <span className="blog__author">
                                    </span>

                                    <span className=" d-flex align-items-center gap-1 section__description">
                                    </span>
                                </div>
                                <p className="section__description">{blog.description}</p>
                                {/* {<p className="section__description">{blog.description}</p>} */}
                                <p className="section__heading">{blog.heading}</p>
                                <p className="section__headingdes">{blog.headingDescription}</p>
                                <p className="section__heading1">{blog.heading2}</p>
                                <p className="section__headingdes1">{blog.headingDescription1}</p>
                                <p className="section__headingsecond">{blog.headingsecond}</p>
                                <p className="section__headingdescriptionvalue">{blog.headingDescriptionvalue}</p>

                                <p className="section__headingsecondFour">{blog.headingsecondFour}</p>
                                <p className="section__headingdescriptionvalue">{blog.headingDescriptionvalueFour}</p>

                                <p className="section__conclusionsecond">{blog.conclusionsecond}</p>
                                <p className="section__conDescriptionsecond">{blog.conDescriptionsecond}</p>

                                <p className="section__heading2">{blog.heading3}</p>
                                <p className="section__headingdes2">{blog.headingDescription2}</p>
                                <p className="section__heading3">{blog.heading4}</p>
                                <p className="section__headingdes3">{blog.headingDescription3}</p>
                                <p className="section__conclusion">{blog.conclusion}</p>
                                <p className="section__headingdes1">{blog.conDescription}</p>

                                {/* <p className="section__headingsecond2">{blog.headingsecond2}</p> */}


                                <p className="section__bestcar">{blog.bestCar}</p>
                                <p className="section__describeCarTitle1">{blog.describeCarTitle1}</p>
                                <p className="section__describeCar1">{blog.describeCar1}</p>
                                <p className="section__describeCarTitle2">{blog.describeCarTitle2}</p>
                                <p className="section__describeCar2">{blog.describeCar2}</p>
                                <p className="section__describeCarTitle3">{blog.describeCarTitle3}</p>
                                <p className="section__describeCar3">{blog.describeCar3}</p>
                                <p className="section__describeCarTitle4">{blog.describeCarTitle4}</p>
                                <p className="section__describeCar4">{blog.describeCar4}</p>
                                <p className="section__describeCarTitle5">{blog.describeCarTitle5}</p>
                                <p className="section__describeCar5">{blog.describeCar5}</p>
                                <p className="section__describeCarTitle6">{blog.describeCarTitle6}</p>
                                <p className="section__describeCar6">{blog.describeCar6}</p>
                            </div>

                            <div className="comment__list mt-5">
                                <div className="single__comment d-flex gap-3">
                                    <div className="comment__content">
                                    </div>
                                </div>

                                {/* =============== comment form ============ */}
                                <div className="leave__comment-form">
                                    <h1>Leave a Comment</h1>

                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup className="contact__form">
                                            <input type="text" name='name' id='name' placeholder='Name' className='boxes__input' required
                                                value={name} onChange={handleChange} />
                                        </FormGroup>

                                        <FormGroup className="contact__form">
                                            <input type="email" name='email' id='email' placeholder='Email' className='boxes__input' required
                                                value={email} onChange={handleChange} />
                                        </FormGroup>

                                        <FormGroup className="contact__form">
                                            <input type="text" name="comments" id="comments" placeholder="Write Your Comments" className='boxes__input' required
                                                value={comments} onChange={handleChange} />
                                        </FormGroup>
                                        <button className="comment__btn">
                                            Post a Comment
                                        </button>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet>
    );
};

export default BlogDetails;