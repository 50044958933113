import React from 'react';
import "../styles/long-term-car.css";

function LongTermCarRent() {
    return (
        <div className='longterm__operation-container'>
            <div className='longterm__operation-page'>
            </div>
            <div className='longterm__title'>
                Long-Term Car Rentals
                <p>
                    Luxury cars you want to rent forever!                             
                </p>
            </div>
            <div className='longterm__container'>
                <p>
                    At RNK, we understand the importance of providing our clients with a hassle-free
                    rental experience. All of our vehicles are owned by RNK, and we take care of all the
                    maintenance, repairs, and servicing, so you don't have to worry about anything.
                    Additionally, all of our chauffeurs are trained and certified to provide you with a
                    comfortable and safe driving experience.<br/>
                    
                    Our luxury cars are perfect for executives, CEOs, chairmen, investors, and VIPs who
                    want to make a statement. We offer a wide range of luxury vehicles, including high-
                    end sedans and SUVs that are perfect for business travel.
                </p>

            <div className='longterm__special'>
                <h1>Our Special Services-</h1>
                <p>
                    Our cars are equipped with all the latest technology, including GPS, Wi-Fi, and
                    Bluetooth, so you can stay connected while on the road. Our cars also come
                    with comfortable seating, climate control, and premium sound systems,
                    ensuring that you have a comfortable and enjoyable ride.
                </p>
            <div className='longterm__services'>
                <h1>Luxury rental car services of your choice</h1>
                <p>
                Whether you need a luxury car for a month or several years, we can provide you with
                a rental package that suits your requirements.<br/>
               <p className='book__today'> Book with us today! </p>
                </p>
            </div>

            <div className='long__rnk'>
                <h1>Why RNK?</h1>
                <p>
                    If luxury is what you are looking for - you are at the perfect place. RNK offers more
                    than just premium cars. Here is why you should choose our services -
                </p>
            <div className='longterm__chauffer'>
                <h1>Chauffeur-driven luxury</h1>
                <p>
                    When you rent a chauffeur-driven luxury car, you don't have to worry about driving
                    and navigating unfamiliar roads or traffic. A professional chauffeur can handle all
                    aspects of the ride, allowing you to relax, work, or enjoy the scenery.
                </p>
            <div className='longterm__gps'>
                <h1>GPS-enabled</h1>
                <p>
                    A GPS-enabled luxury car is secure and can help you easily navigate roads in
                    unknown locations. This can be particularly helpful if you're travelling to a new city or
                    country and need to find your way around quickly and efficiently.
                </p>
            <div className='longterm__security'>
                <h1>Security</h1>
                <p>
                    A secure luxury car prioritizes the safety of its customers by providing well-
                    maintained and reliable vehicles. This can help in making sure that you arrive at your
                    destination safely and on time.
                </p>
            <div className='longterm__comfort'>
                <h1>Comfort fit for VIPs</h1>
                <p>
                    The vehicles are equipped with features such as plush seating, climate control, and
                    advanced sound systems, allowing you to ride conveniently.
                </p>
            <div className='longterm__extensive'>
                <h1>Extensive fleet of luxury cars</h1>
                <p>
                    With more options, you can select a luxury car that is most suitable for your specific
                    occasion or event. We have various luxury vehicles such as BMW, Audi, Mercedes
                    and so on.
                </p>
            <div className='longterm__support'>
                <h1>24/7 support on-call</h1>
                <p>
                    You will get immediate assistance and help quickly resolve the issue, ensuring your
                    travel plans are maintained.
                </p>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
        </div>
            <div className="accordion">
                <div className='accordion__title'>FAQs</div>
                <div>
                    <input type="checkbox" name='example__accordion' id='section1' className='accordion__input' />
                    <label htmlFor="section1" className='accordion__label'>Who manages the chauffeurs and car maintenance in long-term luxury rentals?</label>
                    <div className='accordion__content'>
                        <p>
                            RNK takes complete care of the car maintenance and chauffeur services.
                        </p>
                    </div>
                </div>
                <div className='main__accordion'>
                <div>
                    <input type="checkbox" name='example__accordion' id='section2' className='accordion__input' />
                    <label htmlFor="section2" className='accordion__label'>How long can I rent a luxury car for long-term rental services?</label>
                    <div className='accordion__content'>
                        <p>
                            The length of rental for long-term luxury car rental services can vary depending
                            on the rental service and the specific terms of the rental agreement. Typically, long-
                            term rentals can range from a few months to a year or more.
                        </p>
                    </div>
                </div>

                <div>
                    <input type="checkbox" name='example__accordion' id='section3' className='accordion__input' />
                    <label htmlFor="section3" className='accordion__label'>Are there any mileage restrictions for long-term luxury car rentals?</label>
                    <div className='accordion__content'>
                        <p>
                            Mileage restrictions can vary depending on the rental service and the specific
                            terms of the rental agreement. It's best to check with us directly for their specific
                            mileage policy.
                        </p>
                    </div>
                </div>
            </div>
            </div>

        </div>
    )
}

export default LongTermCarRent;
