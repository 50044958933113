import React from 'react';
import "../styles/local-duty.css";
import { useNavigate } from 'react-router-dom';


const LocalDuty = () => {
    const navigate = useNavigate();

    return (
        <div className='local__operation-container'>
            <div className='local__operation-page'>
            </div>
            <div className='local__title'>
                Local Travel
                <p className='local__paragraph'>
                    Travel in style with our premium local car rental services.
                </p>
            </div>

            <div className='summary'>
                <p>
                    Whether you are renting a luxury car for local travel or want to make your special
                    occasion memorable or a budget friendly option for a family vacation, RNK is the
                    perfect place.<br/><br/>
                    At <span>RNK - RAMNIRANJAN KEDIA</span> Group, we strive to make your experience of renting
                    a car unforgettable. You will experience quality with every ride.<br/> <br/>
                    Make the most of your ride with comfort and panache. Chauffeur-driven cars offer a
                    chance for you to prioritize your work without worrying about traffic, routes and
                    time.
                </p>

                <div className='special__service'>
                <h1>Our Special Services-</h1>
                <p>
                    <span></span>Find the car of your dreams! Why go for anything less than perfect when you
                    can choose from our extensive fleet of luxury cars available in <b> 110+ cities </b> across India. The cars are well
                    maintained and cleaned for ultimate comfort.<br/><br/>
                    <span></span>From <b> Eight-seater vans to Luxury Volvo buses</b> to luxury sedans, SUVs, and
                    more - our services accommodate large groups of people and we ensure that
                    their traveling experience remains comfortable from start to finish.
                </p>
                <div className='luxury__rent'>
                <h1>Luxury rental car services of your choice</h1>
                <p>
                    Renting a luxury car for local travel can provide a unique and enjoyable experience,
                    whether you're celebrating a special occasion like weddings, corporate events or
                    global summits or simply want to enjoy the comfort and style of a luxury car. <br/>

                <span>
                    For hassle-free and comfortable luxury car rides, book with us today!
                </span>
                </p>
                <div className='why__rnk'>
                    <h1>Why RNK?</h1>
                    <p>
                        If luxury is what you are looking for - you are at the perfect place. RNK offers more
                        sthan just premium cars. Here is why you should choose our services -
                    </p>
                </div>
                <div className='Chauffeur__driven'>
                    <h1>Chauffeur-driven luxury</h1>
                    <p>
                        When you rent a chauffeur-driven luxury car, you don't have to worry about driving
                        and navigating through unfamiliar roads or traffic. A professional chauffeur can
                        handle all aspects of the ride, allowing you to relax, work, or enjoy the scenery.
                    </p>
                </div>
                <div className='gps__enabled'>
                    <h1>GPS-enabled</h1>
                    <p>
                        A GPS-enabled luxury car is secure and can help you easily navigate in unknown
                        locations. This can be particularly helpful if you're travelling to a new city or country
                        and need to find your way around quickly and efficiently. The GPS-enabled system
                        also allows tracking of the vehicle to maintain the security of the passengers.
                    </p>

                    <div className='Security'>
                        <h1>Security</h1>
                        <p>
                            A secure luxury car prioritizes the safety of its customers by providing well-
                            maintained and reliable vehicles. This can help in making sure that you arrive at your
                            destination safely and on time.
                        </p>
                    </div>

                    <div className='Comfort'>
                        <h1>Comfort fit for VIPs</h1>
                        <p>
                            The vehicles are equipped with features such as plush seating, climate control, and
                            advanced sound systems, allowing you to ride conveniently.
                        </p>
                    </div>

                    <div className='Extensive'>
                        <h1>Extensive fleet of luxury cars</h1>
                        <p>
                            With more options, you can select a luxury car that is most suitable for your specific
                            occasion or event. We have various luxury vehicles such as BMW, Audi, Mercedes
                            and so on.
                        </p>
                    </div>

                    <div className='support'>
                        <h1>24/7 support on-call</h1>
                        <p>
                            You will get immediate assistance and help to quickly resolve issues if any, ensuring
                            your travel plans are maintained.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="accordion">
                <div className='accordion__title'>FAQs</div>
                <div>
                    <input type="checkbox" name='example__accordion' id='section1' className='accordion__input' />
                    <label htmlFor="section1" className='accordion__label'>Is renting a luxury car at a local location cheaper in India?</label>
                    <div className='accordion__content'>
                        <p>
                            Renting a luxury car to travel locally in India is much cheaper as compared to
                            other countries.
                        </p>
                    </div>
                </div>
                <div className='main__accordion'>
                <div>
                    <input type="checkbox" name='example__accordion' id='section2' className='accordion__input' />
                    <label htmlFor="section2" className='accordion__label'>What luxury car models are available for rent for local travel?</label>
                    <div className='accordion__content'>
                        
                        <p>
                            At RNK luxury car rental services, you can find wide range of cars starting from
                            a standard sedan to high-end car models, including BMW, Mercedes-Benz, Audi
                            and more. The specific models available may vary depending on the location and
                            availability. <button onClick={() => { navigate('/fleet') }} className="link__fleet-feild">Click Here</button> for our entire fleet
                        </p>
                    </div>
                </div>

                <div>
                    <input type="checkbox" name='example__accordion' id='section3' className='accordion__input' />
                    <label htmlFor="section3" className='accordion__label'>Can you take rental cars out of state in India?</label>
                    <div className='accordion__content'>
                        <p>
                            All our cars at RNK have all India permit and can be taken anywhere in India.
                        </p>
                    </div>
                </div>

                <div>
                    <input type="checkbox" name='example__accordion' id='section4' className='accordion__input' />
                    <label htmlFor="section4" className='accordion__label'>Are your luxury vehicles equipped with advanced security features?</label>
                    <div className='accordion__content'>
                        <p>
                            Absolutely. All our luxury vehicles are equipped with state-of-the-art security
                            features, including advanced alarm systems, GPS tracking, and secure locking
                            mechanisms, to safeguard against theft or unauthorized access.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )

};

export default LocalDuty;
