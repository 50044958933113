//import all images from assets/images directory
/* eslint-disable no-unused-vars */

import img01 from '../all-image/fleetImage/mercedes2023.png';
import img02 from '../all-image/fleetImage/mercedesEImage1.png';
// import img03 from "../all-image/fleetImage/bmwSeries7.png"
import img03 from "../all-image/fleetImage/bmw7SeriesCar1.png"


import img04 from '../all-image/fleetImage/mercedesV1.png';
import img05 from '../all-image/fleetImage/mgle.png';
import img06 from '../all-image/fleetImage/bmwSeries53.png';

import img07 from '../all-image/fleetImage/fortuners1.png';
import img08 from '../all-image/fleetImage/highCross.png';
import img09 from '../all-image/fleetImage/byeE6.png';

import img10 from '../all-image/fleetImage/toyotaInnovaCar.png';
import img11 from '../all-image/fleetImage/hondaCity1.png';
import img13 from '../all-image/fleetImage/marutiDzier1.png';

import img14 from '../all-image/fleetImage/camryCar1.png';
import img15 from '../all-image/fleetImage/kiaCarenImg.png';
import img16 from '../all-image/fleetImage/ciazMarutiImg.png';

import img18 from '../all-image/fleetImage/fotonCar2.png';
import img17 from '../all-image/fleetImage/cemrayImg1.png';
import img19 from '../all-image/fleetImage/toyotaCoaster.png';

import img20 from '../all-image/fleetImage/Urbania-Van.png';
import img21 from '../all-image/fleetImage/coster1.png';
import img22 from '../all-image/fleetImage/volvoBus1.png';


// this image for the show off //
import desailImg from "../all-image/logo/diesel.png";



export const carData = [
    {
        id: 1,
        brand: "mercedes",
        rating: 4.5,
        carName: "Mercedes S Class",
        imgUrl: img01,
        model: "mercedes",
        price: 18000,
        person: 5,
        luggage: 3,
        facilities: desailImg,
        speed: "30kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "automatic",
        description: "In the Nissan Magnite, we have ensured every journey is a lavish one. Now, always drive in an environment where incredible versatility and extravagance intersect. All this, to match the bold, new exterior design. Time to experience beautiful.",
    },

    {
        id: 2,
        brand: "mercedesgle",
        rating: 3.7,
        carName: "Mercedes E Class",
        imgUrl: img02,
        model: "e class",
        price: 16000,
        person: 4,
        luggage: 2,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 3,
        brand: "mercedese",
        rating: 4.4,
        carName: "BMW 7 Series",
        imgUrl: img03,
        model: "series5",
        price: 21000,
        person: 4,
        luggage: 3,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 4,
        brand: "Mercedes",
        rating: 4.5,
        carName: "Mercedes V Class",
        imgUrl: img04,
        model: "Q5",
        price: 17500,
        person: 6,
        luggage: 4,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 5,
        brand: "electric",
        rating: 4.8,
        carName: "Mercedes GLE",
        imgUrl: img05,
        model: "safari",
        price: 23500,
        person: 4,
        luggage: 3,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 6,
        brand: "bmw",
        rating: 4.5,
        carName: "BMW 5 Series",
        imgUrl: img06,
        model: "series5",
        price: 12500,
        person: 5,
        luggage: 4,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 7,
        brand: "Toyota",
        rating: 4.0,
        carName: "Toyota Fortuner",
        imgUrl: img07,
        model: "series5",
        price: 14500,
        person: 8,
        luggage: 4,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 8,
        brand: "highcross",
        rating: 4.5,
        carName: "Toyota HyCross",
        imgUrl: img08,
        model: "4",
        price: 16000,
        person: 4,
        luggage: 4,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 9,
        brand: "Hycross",
        rating: 4.5,
        carName: "BYD E6 (Electric Vehicle)",
        imgUrl: img09,
        model: "4",
        price: 16000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 10,
        brand: "toyota",
        rating: 4.5,
        carName: "Toyota Innova Crysta",
        imgUrl: img10,
        model: "4",
        price: 12000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 11,
        brand: "hondacity",
        rating: 4.5,
        carName: "Honda City",
        imgUrl: img11,
        model: "4",
        price: 19000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 12,
        brand: "honda",
        rating: 4.5,
        carName: "Maruti Dzire",
        imgUrl: img13,
        model: "4",
        price: 59000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 13,
        brand: "toyota",
        rating: 4.5,
        carName: "Toyota Corolla Altis",
        imgUrl: img14,
        model: "4",
        price: 19000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 14,
        brand: "kia",
        rating: 4.5,
        carName: "Kia Carens",
        imgUrl: img15,
        model: "4",
        price: 19000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 15,
        brand: "VOLVO BUS",
        rating: 4.5,
        carName: "Maruti Ciaz",
        imgUrl: img16,
        model: "4",
        price: 19000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 16,
        brand: "VOLVO BUS",
        rating: 4.5,
        carName: "Toyota Camry Hybrid",
        imgUrl: img17,
        model: "4",
        price: 19000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 17,
        brand: "VOLVO BUS",
        rating: 4.5,
        carName: "Foton Van 9 Seater",
        imgUrl: img18,
        model: "4",
        price: 19000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 19,
        brand: "coaster",
        rating: 4.5,
        carName: "Toyota Commuter",
        imgUrl: img19,
        model: "4",
        price: 19000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 20,
        brand: "Urban",
        rating: 4.5,
        carName: "Urbania Bus 17 Seater",
        imgUrl: img20,
        model: "4",
        price: 19000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 21,
        brand: "coaster",
        rating: 4.5,
        carName: "Toyota Coaster",
        imgUrl: img21,
        model: "4",
        price: 19000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },

    {
        id: 22,
        brand: "coaster",
        rating: 4.5,
        carName: "Luxury 45 Seater Bus Air Condition",
        imgUrl: img22,
        model: "4",
        price: 19000,
        person: 12,
        luggage: 6,
        speed: "40kmpl",
        gps: "GPS Navigation",
        seatType: "Heated Seat",
        automatic: "no automatic",
        description: "The Toyota Prius has 1 Petrol Engine on offer. The Petrol engine is 1798 cc . It is available with Automatic transmission.Depending upon the variant and fuel type the Prius has a mileage of 26.27 kmpl & Ground clearance of Prius is 135mm. The Prius is a 5 seater 4 cylinder car and has length of 4540mm, width of 1760mm and a wheelbase of 2700mm.",
    },
];

export default carData;

