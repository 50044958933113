import React from "react";
import { Col } from "reactstrap";
// import { Link } from "react-router-dom";
import "../../styles/car-item.css";
import { useNavigate } from 'react-router-dom';
// import Person2Icon from '@mui/icons-material/Person2';
// import {HiUser} from 'react-icons/hi';

const CarItem = (props) => {
    const { imgUrl,  carName } = props.item;
    const navigate = useNavigate();

    return (
        <Col lg="4" md="4" sm="6" className="mb-5">
      <div className="car__item">
        <div className="car__img">
          <img src={imgUrl} alt="" className="w-100" />
        </div>

        <div className="car__item-content mt-4">
          <h4 className="section__title text-center">{carName}</h4>
          {/* <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
            <span className=" d-flex align-items-center gap-1">
              <i class="ri-car-line"></i> {model}
            </span>
            <span className=" d-flex align-items-center gap-">
              <i class="ri-settings-2-line"></i> {automatic}
            </span>
            <span className=" d-flex align-items-center gap-1">
              <i class="ri-timer-flash-line"></i> {speed}
            </span>
          </div> */}

          {/* <button className=" w-50 car__item-btn car__btn-rent"> */}
          <button onClick={() => { navigate('/LocalCarBookingForm') }} className=" w-50 car__item-btn car__btn-rent">Rent</button>
          {/* </button> */}

          {/* <button className=" w-50 car__item-btn car__btn-details"> */}
            {/* <Link to={`/MercedesClass`}>Details</Link> */}
            <button onClick={() => { navigate('/MercedesClass') }} className=" w-50 car__item-btn car__btn-details">Details</button>
          {/* </button> */}
        </div>
      </div>
    </Col>
    );
};

export default CarItem;