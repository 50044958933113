/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import '../../styles/cards.css';
import { useNavigate } from 'react-router-dom';

import img01 from "../../assets/all-image/carsimage/localDuty.jpeg";
import img02 from "../../assets/all-image/carsimage/hotelDesk.png";
import img03 from "../../assets/all-image/carsimage/AirportCarPickUp.jpg";
import img04 from "../../assets/all-image/carsimage/vipService2.jpg";
import img05 from "../../assets/all-image/carsimage/longTermService.jpg";
import img06 from "../../assets/all-image/carsimage/localDuty.jpg";


const Cards = () => {
    const navigate = useNavigate();
    return (
        <div className="cards">
            <div className="card card1">
                <div className="container__cards">
                    <img src={img01} alt="Local Duty" />
                </div>
                <div className="details">
                    <h3 onClick={() => { navigate('/LocalDuty') }}>Local Duty</h3>
                    <h6>On-Time. Comfortable. Hygienic Rides.</h6>
                    <p>
                        Local travel, with one-way or two-way rentals available. 
                        Large groups can also be accommodated.
                    </p>
                    <button onClick={() => { navigate('/LocalDuty') }} className="local__duty">Read More</button>                   
                </div>
            </div>

            <div className="card card3">
                <div className="container__cards">
                    <img src={img03} alt="Airport" />
                </div>
                <div className="details">
                    <h3 onClick={() => { navigate('/Airport') }}>Airport and Railway Travel</h3>
                    <h6>Punctual. Smooth. Effortless.</h6>
                    <p>
                        Local travel, with one-way or two-way rentals available. 
                        Large groups can also be accommodated.
                        You can also book a car for Railway Station pick up and drop.
                    </p>
                    <button onClick={() => { navigate('/Airport') }} className="airport__railway">Read More</button>                   
                </div>
            </div>
           
            <div className="card card4">
                <div className="container__cards">
                    <img src={img04} alt="Vip Service" />
                </div>
                <div className="details">
                    <h3 onClick={() => { navigate('/VipServices') }}>VIP Travel</h3>
                    <h6>Secure. Stress-Free. Personalised.</h6>
                    <p>
                        From BMW, and Mercedes to Audi - choose from the top luxury cars. 
                        Luxury vehicles for groups can also be accommodated.
                        You can enjoy stress free ride with our luxury cars.
                    </p>
                    <button onClick={() => { navigate('/VipServices') }} className="vip__travel">Read More</button>                   
                </div>
            </div>

            <div className="card card5">
                <div className="container__cards">
                    <img src={img05} alt="Long Term Car Rental" />
                </div>
                <div className="details">
                    <h3 onClick={() => { navigate('/LongTermCarRent') }}>Long Term Rentals</h3>
                    <h6>You drive - We maintain! </h6>
                    <p>
                        Choose from a fleet of luxury cars. Book cars for  the long term without the hassle of 
                        maintenance and driver training.
                    </p>
                    <button onClick={() => { navigate('/LongTermCarRent') }} className="long__term-rentals">Read More</button>                   
                </div>
            </div>

            <div className="card card6">
                <div className="container__cards">
                    <img src={img06} alt="Luxury Travel" />
                </div>
                <div className="details">
                    <h3 onClick={() => { navigate('/LuxuryTravel') }}>Outstation Travels</h3>
                    <h6>Travel in luxury - no matter the destination!</h6>
                    <p>
                    Travel the long miles comfortably and securely with your friends and family! Luxury 
                    car rentals catering to religious travel can also be customized. 
                    </p>
                    <button onClick={() => { navigate('/LuxuryTravel') }} className="outstation__travels">Read More</button>                   
                </div>
            </div>

            <div className="card card2">
                <div className="container__cards">
                    <img src={img02} alt="Hotel/Operation" />
                </div>
                <div className="details">
                    <h3 onClick={() => { navigate('/HotelOperation') }}>Hotel/Operation</h3>
                    <h6>On-call Service. Fleet of Cars.</h6>
                    <p>
                    Customisable packages are available. Inter-city travel for large groups can be 
                    accommodated. 
                    </p>
                    <button onClick={() => { navigate('/HotelOperation') }} className="hotel__operation">Read More</button>                   
                </div>
            </div>
            
        </div>
    );
};

export default Cards;

