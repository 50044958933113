import React from 'react'

function Details() {
    return (
        <div>
            Detials
        </div>
    )
}

export default Details;