import React from 'react';
import "../styles/our-network.css";
import Ournetwork from "../assets/all-image/slider/outNetwork.jpg";
import { useNavigate } from 'react-router-dom';


function OurNetwork() {
    const navigate = useNavigate();

  return (
    <div className='ournetwork__container'>
        <div className='ournetwork__banner'>
            <img src={Ournetwork} alt="" />
        </div>

        <div className='ournetwork__title'>
            <h1>Our Pan India Network: </h1>
        </div>

        <div className='ournetwork__cityname'>
            <table id="customers">
                <tr>
                    <td>AGARTALA</td>
                    <td>AHMEDABAD</td>
                    <td>AIZAWL</td>
                    <td>AMRITSAR</td>
                </tr>
                <tr>
                    <td>ANKLESHWAR</td>
                    <td>ASANSOL</td>
                    <td>AURANGABAD</td>
                    <td>BAGDOGRA</td>
                </tr>
                <tr>
                    <td>BAHARAMPUR</td>
                    <td>BALASORE</td>
                    <td>BENGALURU</td>
                    <td>BATHINDA</td>
                </tr>
                <tr>
                    <td>BELGAVI</td>
                    <td>BELLARY</td>
                    <td>BHANJANAGAR</td>
                    <td>BHAVANIPATNA</td>
                </tr>
                <tr>
                    <td>BHILAI</td>
                    <td>BHOPAL</td>
                    <td>BHUBANESHWAR</td>
                    <td>BIHAR</td>
                </tr>
                <tr>
                    <td>CALICUT</td>
                    <td>CHANDIGARH</td>
                    <td>CHANDRAPUR</td>
                    <td>CHENNAI</td>
                </tr>
                <tr>
                    <td>COIMBATORE</td>
                    <td>CUTTACK</td>
                    <td>DEHRADUN</td>
                    <td>DELHI</td>
                </tr>
                <tr>
                    <td>DHARAMGARH</td>
                    <td>DHARMASHALA</td>
                    <td>DHULE</td>
                    <td>ERNAKULAM</td>
                </tr>
                <tr>
                    <td>GOA</td>
                    <td>GORAKHPUR</td>
                    <td>GULBARGA</td>
                    <td>GUNTUR</td>
                </tr>
                <tr>
                    <td>GURGAON</td>
                    <td>GUWAHATI</td>
                    <td>GWALIOR</td>
                    <td>HARIDWAR</td>
                </tr>
                <tr>
                    <td>HOSAPETE</td>
                    <td>HUBLI</td>
                    <td>HYDERABAD</td>
                    <td>IMPHAL</td>
                </tr>
                <tr>
                    <td>INDORE</td>
                    <td>JABALPUR</td>
                    <td>JAIPUR</td>
                    <td>JALANDHAR</td>
                </tr>
                <tr>
                    <td>JAMMU</td>
                    <td>JAMSHEDPUR</td>
                    <td>JHARSUGDA</td>
                    <td>JODHPUR</td>
                </tr>
                <tr>
                    <td>KADAPA</td>
                    <td>KANPUR</td>
                    <td>KOCHI</td>
                    <td>KOLHAPUR</td>
                </tr>
                <tr>
                    <td>KOLKATA</td>
                    <td>KORAPUT</td>
                    <td>KOTA</td>
                    <td>KUMBAKONAM</td>
                </tr>
                <tr>
                    <td>KUTCH</td>
                    <td>LUCKNOW</td>
                    <td>LUDHIANA</td>
                    <td>MADHURAI</td>
                </tr>
                <tr>
                    <td>MAHABALESHWAR</td>
                    <td>MALDA</td>
                    <td>MANGALORE</td>
                    <td>MOHALI</td>
                </tr>
                <tr>
                    <td>MORADABAD</td>
                    <td>MUMBAI</td>
                    <td>MYSORE</td>
                    <td>NAGPUR</td>
                </tr>
                <tr>
                    <td>NANDED</td>
                    <td>NASHIK</td>
                    <td>NELLORE</td>
                    <td>NOIDA</td>
                </tr>
                <tr>
                    <td>PANTNAGAR</td>
                    <td>PARLAKHEMUNDI</td>
                    <td>PATHANKOT</td>
                    <td>PATIALA</td>
                </tr>
                <tr>
                    <td>PATNA</td>
                    <td>PONDICHERRY</td>
                    <td>PRAYAGRAJ</td>
                    <td>PUNE</td>
                </tr>
                <tr>
                    <td>RAIPUR</td>
                    <td>RAJAHMUNDRY</td>
                    <td>RAJKOT</td>
                    <td>RANCHI</td>
                </tr>
                <tr>
                    <td>RAYAGADA</td>
                    <td>ROORKEE</td>
                    <td>RUDRAPUR</td>
                    <td>SAMBALPUR</td>
                </tr>
                <tr>
                    <td>SECUNDERABAD</td>
                    <td>SHIRDI</td>
                    <td>SHIVAMOGGA</td>
                    <td>SILCHAR</td>
                </tr>
                <tr>
                    <td>SILIGURI</td>
                    <td>SILVASSA</td>
                    <td>SOLAPUR</td>
                    <td>SRINAGAR</td>
                </tr>
                <tr>
                    <td>SULTANPUR</td>
                    <td>SURAT</td>
                    <td>THEKKEMALA</td>
                    <td>THIRUVANANTHAPURAM</td>
                </tr>
                <tr>
                    <td>TIRUCHIRAPALLI</td>
                    <td>TIRUPATI</td>
                    <td>TRICHUR</td>
                    <td>TRIVANDRUM</td>
                </tr>
                <tr>
                    <td>TUTICORIN</td>
                    <td>UDAIPUR</td>
                    <td>UTTAR PRADESH</td>
                    <td>VADODARA</td>
                </tr>
                <tr>
                    <td>VARANASI</td>
                    <td>VIJAYWADA</td>
                    <td>VISHAKHAPATNAM</td>
                    <td>ZIRAKPUR</td>
                </tr>
            </table>
            <div className='button__bookingcar'>
                <button onClick={() => { navigate('/LocalCarBookingForm') }}>Book Your Car</button>
            </div>
        </div>
    </div>
  )
}

export default OurNetwork;
