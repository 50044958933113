import React, { useState } from "react";
import "../styles/thank.css";

function BookingPage() {

    /** show the input data to the welcome page */
    const [formData, setFormData] = useState({});
    const [submittedData, setSubmittedData] = useState(null);

    /**this code is used to store the form data to the google sheet  */
    const [data, setData] = useState({
        name: "",
        phone: "",
        email: "",
        city: "",
        vehicle: "",
        date: "",
        duty: "",
    });

    const { name, phone, email, city, vehicle, date, duty } = data;

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value });
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmittedData(formData);

        setTimeout(() => {
            setSubmittedData(null);
        }, 5000);

        try {
            const response = await fetch("https://v1.nocodeapi.com/anshujha1999/google_sheets/VyfBrkRwVIzscrre?tabId=<LocalCarBookingData>", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([[name, phone, email, city, vehicle, date, duty, new Date().toLocaleString()]])
            });
            await response.json()
            setData({ ...data, name: "", phone: "", email: "", city: "", vehicle: "", date: "", duty: "" })

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <form className='mobile__input' onSubmit={handleSubmit}>
            <h1>Book Your Car</h1>

            <div className="mobile__input-feild">
                <label>Enter Your Name</label><br/>
                <input type="text" name='name' id='name' placeholder='Name' required autocomplete="off" 
                value={name} onChange={handleChange} />

            </div>

            <div className="mobile__input-feild">
                <label>Enter Your Phone</label><br/>
                <input type="tel" name="phone" id="phone" placeholder="Phone" required autocomplete="of"
                value={phone} onChange={handleChange} />
            </div>

            <div className="mobile__input-feild">
                <label>Enter Your Email</label><br />
                <input type="email" name="email" id="email" placeholder="Email" required autocomplete="of"
                value={email} onChange={handleChange} />
            </div>

            <div className="mobile__input-feild">
                <label htmlFor="">Enter Your City</label><br />
                <input type="text" name="city" id="city" placeholder="City" required autocomplete="of" 
                value={city} onChange={handleChange}/>
            </div>

            <div className="mobile__input-feild">
                <label>Enter Vehicle Name</label><br/>
                <input type="text" name="vehicle" id="vehicle" placeholder="Vehicle Type" required autocomplete="off"
                value={vehicle} onChange={handleChange} />
            </div>

            <div className="mobile__input-feild">
                <label>Select Date</label><br/>
                <input type="date" name="date" id="date" placeholder="Date" required autocomplete="of"
                value={date} onChange={handleChange} />
            </div>

            <div className="mobile__input-feild">
                <label>Enter Duty Types</label>
                <input type="text" name="duty" id="duty" placeholder="Duty Type" required autocomplete="of"
                value={duty} onChange={handleChange} />
            </div>

            <div className="mobile__input-feild-btn">
            <button type="submit">Book Now</button>
            </div>
            {
                            submittedData && (
                                <div className="all__value-data">
                                    <h6>Dear {submittedData.name}</h6>
                                    <span>Thank you for contacting RNK Booking team will connect you very soon!</span>
                                    <ul>
                                        <li>Name:{submittedData.name}</li>
                                        <li>Phone:{submittedData.phone}</li>
                                        <li>Email:{submittedData.email}</li>
                                        <li>City:{submittedData.city}</li>
                                        <li>Car Type:{submittedData.vehicle}</li>
                                        <li>Booking Date:{submittedData.date}</li>
                                        <li>Duty Type:{submittedData.duty}</li>
                                    </ul>
                                </div>
                            )
                        }
        </form>
    );
};

export default BookingPage;
