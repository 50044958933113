import React from "react";
import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import driveImg from "../assets/all-image/slider/aboutImgPage.png";
import awardImg from "../assets/all-image/PageImg/awardGold.jpeg";
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection />
      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__png-img">
                <img src={driveImg} alt="" className="w-100" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title-about">About Us</h2>
                remarkable journey in 1969. Armed with a passion for excellence
                and a deep understanding of In the bustling city of Mumbai, a
                visionary named Ramniranjan Kedia along with our co - founder
                Mr. Kamal Kedia embarked on a the travel industry, he laid the
                foundation of RNK - the Ramniranjan Kedia Group. Little did he
                know that his venture would go on to become synonymous with the
                ultimate in upgraded car rental services and premier travel
                experiences. From its humble beginnings, the RNK group rapidly
                expanded its operations, establishing itself as a trusted name
                in the world of travel. With major bases in all the prominent
                cities of India, RNK's brand became a beacon of reliability and
                innovation.
                <h1>Accolades and Appreciation</h1>
                <p className="about__page-paragraph">
                  The Government of India, through the Department of Tourism,
                  bestowed its recognition upon the RNK group, further
                  validating its committment to exceptional service and
                  unwavering quality. It was a testament to the tireless efforts
                  of the entire team and their unyielding pursuit of excellence.
                </p>
                <h1>Trust. Excellence. Comfort.</h1>
                <p className="about__page-secondpara">
                  Throughout its 54-year journey, RNK consistently surpassed
                  expectations and garnered accolades from various segments of
                  society. Their clientele included top-notch business houses,
                  entertainment and event management companies, banks,
                  multinationals, financial institutions, and diplomatic
                  missions. Public sector companies like the Government of India
                  Departments, such as the Protocol Division and the Ministry of
                  External Affairs - Media Dept, also relied on RNK's impeccable
                  services. Even the esteemed Lok Sabha Secretariat's Protocol 1
                  trusted RNK for their travel needs. The RNK Group's extensive
                  range of services catered to corporate and individual
                  travellers' diverse requirements. Whether it was a seamless
                  travel experience, a luxurious car rental, or a comprehensive
                  travel package, RNK has it all covered. Today, as you embark
                  on your own journey, you can trust the RNK Group to be your
                  reliable companion. With their unwavering commitment to
                  excellence, their profound industry knowledge, and their
                  passion for providing the highest standards of service, RNK
                  will make your travel dreams come true. Join the ranks of the
                  countless satisfied customers who have experienced the magic
                  of RNK and let them weave a tale of unforgettable memories for
                  you.
                </p>
              </div>
            </Col>
            <div class="wrapper">
              <h1>Our Awards</h1>
            </div>
            <div className="award__section">
              <div className="our__award-section">
                <img src={awardImg} alt="" width="300" height="200" />
                <p>
                  this is the first award of rnk
                  <br />
                  this award goes to rnk team
                  <br />
                  <span>1999 to 2000</span>
                </p>
              </div>

              <div className="our__award-section">
                <img src={awardImg} alt="" width="300" height="200" />
                <p>
                  this is the first award of rnk
                  <br />
                  this award goes to rnk team
                  <br />
                  <span>1999 to 2000</span>
                </p>
              </div>

              <div className="our__award-section">
                <img src={awardImg} alt="" width="300" height="200" />
                <p>
                  this is the first award of rnk
                  <br />
                  this award goes to rnk team
                  <br />
                  <span>1999 to 2000</span>
                </p>
              </div>

              <div className="our__award-section">
                <img src={awardImg} alt="" width="300" height="200" />
                <p>
                  this is the first award of rnk
                  <br />
                  this award goes to rnk team
                  <br />
                  <span>1999 to 2000</span>
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
