import React from 'react'
import "../../styles/step-section.css";
// import TouchAppIcon from '@mui/icons-material/TouchApp';
// import CarRentalIcon from '@mui/icons-material/CarRental';
// import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
// import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

import bookCar from "../../assets/all-image/stepSection/car-rentalblack.png";
import pickUp from "../../assets/all-image/stepSection/pickup.jpg";
import enjoyRide from "../../assets/all-image/stepSection/enjoy.jpg";
import dropCar from "../../assets/all-image/stepSection/drop.png";

const StepSection = () => {
    return (
        <div>
            <section class="step-wizard">
                <ul class="step-wizard-list">
                    <li class="step-wizard-item">
                        <span class="progress-count">1</span>
                        <span class="progress-label">Book Your Car</span><br />
                        <div className='icons_list'>
                            <img src={bookCar} alt="" width={150} height="100" />
                            <p>Our Reservation Team Confirm Your Booking</p>
                        </div>

                    </li>
                    <li class="step-wizard-item current-item">
                        <span class="progress-count">2</span>
                        <span class="progress-label">Your Pick-Up</span><br />
                        <div className='icons_list1'>
                            <img src={pickUp} alt="" width={150} height="100" />
                            <p>Your chauffeur will see you at your pick-up</p>
                        </div>

                    </li>
                    <li class="step-wizard-item current-item1">
                        <span class="progress-count">3</span>
                        <span class="progress-label3">Enjoy Your Ride</span><br />
                        <div className='icons_list2'>
                            <img src={enjoyRide} alt="" width={150} height="100" />
                            <p>Enjoy your journey with professionally Trained chauffeur</p>
                        </div>

                    </li>
                    <li class="step-wizard-item current-item2">
                        <span class="progress-count">4</span>
                        <span class="progress-label4">Drop At Destination</span><br />
                        <div className='icons_lits3'>
                            <img src={dropCar} alt="" width={150} height="100" />
                            <p>Our chauffeur will drop you at your Destination safely</p>
                        </div>
                    </li>
                </ul>
            </section>
        </div>
    )
};

export default StepSection;
