import React from "react";
import { Container, Row, Col } from "reactstrap";
import '../../styles/about-section.css';
import aboutImg from '../../assets/all-image/slider/aboutImg.png';
import { useNavigate } from 'react-router-dom';


const AboutSection = ({ aboutClass }) => {
    const navigate = useNavigate();

    return <section className="about__section"
        style={
            aboutClass === 'aboutPage'
                ? { marginTop: "0px" }
                : { marginTop: '-150px' }
        }
    >
        <Container>
            <Row>
                <Col lg='6' md='6'>
                    <div className="about__section-content">
                        <div className="section__subtitle-aboutPage">
                            <h2>Redefining Luxury Car Rental Services</h2>
                            <p className="section__description-details">
                            <p className="first__line">Offering excellence since <b>1969</b>, across <b className="cities__name">110+ cities Pan India.</b></p><br />
                            At RNK - RAMNIRANJAN KEDIA Group, we make your car rental experience unforgettable. 
                            We offer premium services with every ride. From professional chauffeurs to well-maintained 
                            rides - we stand for LUXURY! 
                            <button onClick={() => { navigate('/About') }} className="link_tab">See More</button>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col lg='6' md='6'>
                    <div className="about__img">
                        <img src={aboutImg} alt="" />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
}

export default AboutSection;